import React from "react";
import "./reviews.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

export default function Reviews() {
  const [slidesAtATime, setSlidesToShow] = useState(1);

  useEffect(() => {
    function setWidth() {
      if (window.innerWidth >= 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 700) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    }
    const handleResize = () => {
      setWidth();
    };
    setWidth();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: slidesAtATime,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    // centerMode: true,
    swipeToSlide: true,
    customPaging: (i) => <div className="custom-dot"></div>,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    // centerPadding: "20px", // Adjust this value to control the padding around the center slide
  };
  return (
    <div className="reviews-cont">
      <div className="review-title-cont">
        <h2>Here’s what our client have to say about us</h2>
      </div>
      <div className="review-carousel">
        <Slider {...settings} className="slider-cont">
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <div className="review-carousel-card">
              <h2>Sarah K.</h2>
              <p>
                We were struggling to keep up with our growing business until we
                started using Thatapp.io. Its scalability and comprehensive
                automation have made a significant difference{" "}
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
