import React, { useEffect, useState } from "react";

import "../index.css";
import Logo from "../../../assets/images/logosync.svg";
import bg from "../../../assets/images/authbg.png";

import { Link } from "react-router-dom";
import { verifyEmail } from "../../../services/auth";
import Loader from "../../../components/Loader/Loader";

const VerifyEmail = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [validator, setValidator] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const token = url.searchParams.get('token');

    if (email && token) {
      setValidator(true);
      verify(email, token);
    } else {
      setLoading(false)
    }

  }, [props.location.search]);

  const verify = async (email, token) => {
    setLoading(true);
    try {
      let query = `?email=${email}&token=${token}`;
      let response = await verifyEmail(query);

      if (response.status) {
        setLoading(false);
        setSuccess(true);
      }
    } catch (x) {
      setLoading(false);
      setSuccess(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="auth">
      {validator ?
        (
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="auth__sidebar"  style={{
                backgroundImage: `url(${bg})`,
                backgroundSize:`cover`,
                backgroundPosition:`center`
            }}>
                <div className="layer">
                  <div className="custom__container__80">
                    <img src={Logo} alt="logo" />
                    <h1 className="title">
                      Welcome to <br /> ThatApp!
                </h1>
                    <p className="description">
                      It's our job to ensure that you're never lost in the clouds.
                      Gain a refreshing perspective of your business processes and
                      strategic initiatives through the eyes of our skilled workflow
                      architects and system integrators.
                </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="auth__component">
                <div className="custom__container__80">
                  <div className="auth__form__container mt-5 text-center">
                    {success ? (
                      <div className="verify__result">
                        <i className="far fa-check-circle"></i>
                        <h3>Your Email was verified successfully !</h3>
                        <Link to="/login">Log in to your account</Link>
                      </div>
                    ) : (
                      <div className="verify__result">
                        <i className="far fa-times-circle"></i>
                        <h3>Your Email was not verified successfully !</h3>
                        <Link to="/account/resend">
                          Resend Verification Link
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="auth__sidebar" style={{
                backgroundImage: `url(${bg})`,
                backgroundSize:`cover`,
                backgroundPosition:`center`
            }}>
                <div className="layer">
                  <div className="custom__container__80">
                    <img src={Logo} alt="logo" />
                    <h1 className="title">
                      Welcome to <br /> ThatApp!
              </h1>
                    <p className="description">
                      It's our job to ensure that you're never lost in the clouds.
                      Gain a refreshing perspective of your business processes and
                      strategic initiatives through the eyes of our skilled workflow
                      architects and system integrators.
              </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="auth__component">
                <div className="custom__container__80">
                  <div className="auth__form__container mt-5 text-center">
                    <div className="verify__result">
                      <i className="far fa-times-circle"></i>
                      <br />
                      <Link to="/login" className="text-center">
                        Login
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </div>
  );
};

export default VerifyEmail;
