import React, { useState } from "react";
import Logo from "../../../assets/icons/logo.svg";
import bg from "../../../assets/images/authbg.png";
import Asterisk from "../../../components/Common/Asterisk";
import { Link, useLocation } from "react-router-dom";
import { registerUser } from "../../../services/auth";
import "../index.css";

const Register = (props) => {
  const { search } = useLocation();
  const podioPartner = new URLSearchParams(search).get("partner");

  const getEmailQuery = new URLSearchParams(props.location.search).get("email");
  let queryEmail = getEmailQuery ? getEmailQuery : "";

  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: queryEmail,
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const hidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }

    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = new URL(window.location.href);
    const invite_code = url.searchParams.get("invite_code");
    const { firstName, lastName, email, password } = data;
    const userDetails = {
      name: firstName + " " + lastName,
      email,
      password,
    };

    if (invite_code !== null) {
      userDetails.invite_code = invite_code;
    }
    if (podioPartner !== null) {
      userDetails.podioPartner = podioPartner;
    }

    try {
      const response = await registerUser(userDetails);

      if (response.status) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      localStorage.removeItem("partnerId");

      //Redirect the user
      props.history.push("/account/confirm-email");
    } catch (x) {
      setLoading(false);
    }
  };

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div
            className="auth__sidebar"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <div className="layer">
              <div style={{marginTop: "10%"}} className="custom__container__80">
                <Link className="link__text" to="/">
                  <img src={Logo} alt="logo" className="logo" />
                </Link>
                <h1 className="title">Welcome to ThatApp!</h1>
                <p className="description">
                  It is our job to ensure that you are never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="auth__form__container">
                <h1>Sign up to thatApp</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>
                        First Name <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        name="firstName"
                        value={data.firstName}
                        required
                        onChange={handleChange}
                        type="text"
                        placeholder="John"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Last Name <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        name="lastName"
                        value={data.lastName}
                        required
                        type="text"
                        placeholder="Doe"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Email <Asterisk />
                      </label>{" "}
                      <input
                        className="form-control"
                        name="email"
                        value={data.email}
                        required
                        type="email"
                        placeholder="johndoe@thatapp.io"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Password <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        type={passwordType}
                        name="password"
                        required
                        placeholder="Password123@"
                        value={data.password}
                        onChange={handleChange}
                      />
                      <i
                        className={`${
                          passwordType === "password"
                            ? "far fa-eye"
                            : "far fa-eye-slash"
                        } field-icon2`}
                        onClick={hidePassword}
                      ></i>
                    </div>
                  </div>
                  <div className="form-group position-relative d-flex flex-wrap align-items-center">
                    <input
                      type="checkbox"
                      // className="form-check-input"
                      id="exampleCheck1"
                      onChange={(e) => setTerms(e.target.checked)}
                    />
                    <label
                      className="form-check-label ml-2"
                      htmlFor="exampleCheck1"
                    >
                      By signing up you agree to our{" "}
                      <a
                        className="link__text"
                        href="https://help.thatapp.io/en/articles/108584-privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        className="link__text"
                        target="_blank"
                        rel="noreferrer"
                        href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
                      >
                        Terms of Service
                      </a>
                      .
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <div style={{ marginTop: "2%" }}>
                      <p className="route__text">
                        Already have an account? &nbsp;
                        <Link className="link__text" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                    <button
                      disabled={!terms || loading}
                      className="btn auth-btn"
                    >
                      {loading ? "Please Wait" : "Sign Up"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
