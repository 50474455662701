import React from "react";
import "./style.css";

import mysql from "../../Images/mysql.png";
import mongodb from "../../Images/mongodb.png";
import sharefile from "../../Images/sharefile.png";
import rightsignature from "../../Images/rightsignature.png";
import postgres from "../../Images/postgres.png";
import podio from "../../Images/podio.png";
import jsonImg from "../../Images/json.png";

import arrUp from "../../Images/arrow-side-up.png";
import arrDown from "../../Images/arrow-side-down.png";

import { motion } from "framer-motion";

export default function Sync() {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      key="sync"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="integration-tab-content"
    >
      <p className="integration-tab-content-title">
        Your Data's Perfect Partner! Seamlessly synchronize, manage, and access
        your data from anywhere, anytime. With intuitive features and robust
        security, Sync ensures your data stays in perfect harmony, empowering
        you to focus on what matters most – growing your business
      </p>
      <div className="integration-workflow-tech-cont">
        <div className="integration-workflow-tech">
          <img src={podio} alt="podio" className="integration-workflow-podio" />
          <div className="integration-tech-name">
            <h2>Podio</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img src={mysql} alt="podio" className="integration-workflow-mysql" />
          <div className="integration-tech-name">
            <h2>MySQL</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img
            src={mongodb}
            alt="podio"
            className="integration-workflow-mongodb"
          />
          <div className="integration-tech-name">
            <h2>MongoDB</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img
            src={sharefile}
            alt="podio"
            className="integration-workflow-sharefile"
          />
          <div className="integration-tech-name">
            <h2>ShareFile</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img
            src={jsonImg}
            alt="podio"
            className="integration-workflow-json"
          />
          <div className="integration-tech-name">
            <h2>JSON</h2>
            <div className="integration-tech-status">
              <img src={arrDown} alt="soon" />
              <p className="integration-soon">Upcoming</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img
            src={rightsignature}
            alt="podio"
            className="integration-workflow-rightsig"
          />
          <div className="integration-tech-name">
            <h2>Right Signature</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        <div className="integration-workflow-tech">
          <img
            src={postgres}
            alt="podio"
            className="integration-workflow-postgres"
          />
          <div className="integration-tech-name">
            <h2>PostgreSQL</h2>
            <div className="integration-tech-status">
              <img src={arrUp} alt="available" />
              <p className="integration-available">Available</p>
            </div>
          </div>
        </div>
        {/* <div className="integration-more-tech-tab">
          <p>See more </p>
          <img src={arrRight} alt="see more" />
        </div> */}
      </div>
      {/* <button className="integration-more-tech">
        <p>See more </p>
        <img src={arrRight} alt="see more" />
      </button> */}
    </motion.div>
  );
}
