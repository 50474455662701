import React, { useState } from "react";
import "../index.css";
import Logo from "../../../assets/images/logosync.svg";
import bg from "../../../assets/images/authbg.png";
import { Link } from "react-router-dom";
import { requestAccountVerification } from "../../../services/auth";
import Asterisk from "../../../components/Common/Asterisk";
import { toast } from "helpers/apiRequests";

const RequestEmailVerification = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const object = { email }
        try {
            await requestAccountVerification(object)
            toast.notify("Check your email for the account verifcation link");
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
        setEmail("");
    };

    return (
        <div className="auth">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <div className="auth__sidebar" style={{
                backgroundImage: `url(${bg})`,
                backgroundSize:`cover`,
                backgroundPosition:`center`
            }}>
                        <div className="layer">
                            <div className="custom__container__80">
                                <img src={Logo} alt="logo" />
                                <h1 className="title">
                                    Welcome to <br /> ThatApp!
                </h1>
                                <p className="description">
                                    It is our job to ensure that you are never lost in the clouds.
                                    Gain a refreshing perspective of your business processes and
                                    strategic initiatives through the eyes of our skilled workflow
                                    architects and system integrators.
                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6">
                    <div className="auth__component">
                        <div className="custom__container__80">
                            <div className="text-right">
                                <p className="route__text">
                                    Remember Password?{" "}
                                    <Link className="link__text" to="/login">
                                        Sign in
                  </Link>
                                </p>
                            </div>
                            <div className="auth__form__container mt-5">
                                <h1 className="pt-5">Account Verification?</h1>
                                <p>
                                    Enter the email address of the organisation you belong to and
                                    we’ll send you instructions to verify your account.
                </p>
                                <form onSubmit={handleSubmit} className="mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label>
                                                Company Email <Asterisk />
                                            </label>
                                            <input
                                                className="form-control"
                                                name="email"
                                                value={email}
                                                required
                                                type="email"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <button className="btn auth-btn" disabled={loading}>
                                        {loading ? "Please Wait" : "Send Verification instructions"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestEmailVerification;
