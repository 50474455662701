import React, { useEffect, useState } from "react";
import nigeria from "../Images/nigeria.png";
import element from "../Images/contact-img.png";
import chevron from "../Images/chevron-down.png";
import "./contact.css";
import "./generalStyle.css";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [checked, setChecked] = useState(false);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <>
      {" "}
      <Header />
      <div className="contact-cont">
        <div className="contact-inner">
          <div className="conatct-title-cont">
            <img src={element} alt="talk to us" />
            <h2>Get in Touch with us</h2>
          </div>
          <form className="contact-form">
            <div className="contact-input-cont">
              <label htmlFor="firstName">First Name</label>
              <input
                placeholder="Enter first name"
                type="text"
                name="firstName"
                id="firstName"
              />
            </div>
            <div className="contact-input-cont">
              <label htmlFor="lastName">Last Name</label>
              <input
                placeholder="Enter last name"
                type="text"
                name="lastName"
                id="lastName"
              />
            </div>
            <div className="contact-input-cont">
              <label htmlFor="email">Email</label>
              <input
                placeholder="Enter your email"
                type="email"
                name="email"
                id="email"
              />
            </div>
            <div className=" contact-input-cont ">
              <label htmlFor="phone">Phone Number</label>
              <div className="contact-phone-cont">
                <div className="contact-countrycode">
                  <img src={nigeria} alt="country code" className="country" />
                  <img src={chevron} alt="select" className="chevron" />
                </div>
                <input
                  placeholder="Enter phone number"
                  type="text"
                  name="phone"
                  id="phone"
                />
              </div>
            </div>
            <div className="contact-input-cont contact-textarea">
              <label htmlFor="message">Message</label>
              <textarea
                placeholder="Enter your message"
                name="message"
                id="message"
              ></textarea>
            </div>
            <div className="contact-terms-cont">
              <div className="contact-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckChange}
                      name="checkedB"
                      sx={{
                        color: "#f0b429",
                        "&.Mui-checked": {
                          color: "#f0b429",
                        },
                      }}
                    />
                  }
                  label={"I agree with Terms of Use and Privacy Policy"}
                />
                {/* <input type="checkbox" name="terms" id="terms" />
                <label htmlFor="terms">
                I agree with Terms of Use and Privacy Policy
                </label> */}
              </div>
              <button className="contact-submit-button" type="button">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
