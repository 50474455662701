import React, { useCallback, useEffect, useState } from "react";
import ModulesLayout from "../common/ModulesLayout";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { fetchActivities } from "../services/clone";
import dayjs from "dayjs";
import LogsModal from "../modals/LogsModal";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import reload from "../../../assets/icons/reloadN.svg";
import clonedataEmpty from "../../../assets/icons/cloneDataEmpty.svg";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    width: "100%",
    margin: "111px auto",
  },
  header: {
    padding: "32px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#3B3B3B",
    marginBottom: "8px",
  },
  text: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    maxWidth: "719px",
  },
  border: {
    width: "100%",
    height: "1px",
    background: "#E1E1E1",
    margin: "24px 0 26px",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    height: "44px",
    width: "497px",
    border: "0.5px solid #9E9E9E",
    borderRadius: "4px 0 0 4px",
    padding: "0px 16px !important",
    backgroundColor: "#fff",
  },
  btn: {
    height: "44px",
    width: "48px",
    minWidth: "auto",
    color: "#fff !important",
    backgroundColor: "#F0B429",
    borderRadius: "0 4px 4px 0",
    textTransform: "capitalize",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
    padding: "21px 0 21px 32px",
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    padding: "21px 0 21px 32px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  center: {
    textAlign: "center",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    color: "#fff !important",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "8px 32px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
    "&:hover": {
      backgroundColor: "#F0B429",
    },
  },
  opacity: {
    opacity: "0.5",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "54px",
  },
  paginationDataBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    height: "32px",
    width: "32px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    border: "1px solid #CFCFCF",
    color: "#3B3B3B",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  },
  activePaginationData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "4px",
    padding: "0px",
    backgroundColor: "#F0B429",
    border: "1px solid #F0B429",
    color: "#fff !important",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "32px",
  },
  forReload: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    backgroundColor: "#fff",
    padding: "2px 32px",
    borderRadius: "5px",
    cursor: "pointer",
    height: "40px",
  },
  forReloadText: {
    color: "black",
    fontWeight: "600",
    fontSize: "18px"
  },
}));

const ActivityLog = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [log, setLog] = useState("");
  const [reloadComponent, setReloadComponent] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const showLogs = (payload) => {
    setLog(payload);
    setOpen(true);
  };

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("log-item"));
    if (id) {
      showLogs(id);
    }
  }, []);

  const paginateData = useCallback(() => {
    return Math.ceil(totalRecord / 5);
  }, [totalRecord]);

  const reloadPage = () => {
    // Toggle the state to trigger re-render
    setReloadComponent(!reloadComponent);

  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetchActivities(page);
        if (response.success) {
          // const filteredData = response.data.data.filter((item) => {
          //   const currentDate = dayjs(); // Current date
          //   const itemDate = dayjs(item.created_at); // Date of the item
          //   const differenceInDays = currentDate.diff(itemDate, "days");
          //   return differenceInDays <= 2;
          // });

          const filteredData = response.data.data;
          setData(filteredData);
          setTotalRecord(filteredData.length);
          setPagination(paginateData());
          
        }
      } catch (ex) {
       
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, paginateData, reloadComponent]);

  const dateFormatter = (date) => {
    return dayjs(date).format("DD-MM-YYYY hh:mm a");
  };

  return (
    <ModulesLayout>
      <div className={classes.layoutContainer}>
        <div className={classes.header}>
          <div className={classes.headerContainer}>
            <div>
              <h4 className={classes.title}>Activity Log</h4>
              <p className={classes.text}>
                Your Comprehensive Tool for Tracking, Monitoring, and Managing
                Data Copies with Utmost Precision and Efficiency
              </p>
            </div>
            <div onClick={reloadPage} className={classes.forReload}>
              <img src={reload} alt="reload" />
              <p className={classes.forReloadText}>Reload</p>
            </div>
          </div>
          <div className={classes.border}></div>
          {/* <div className={classes.inputGroup}>
            <input className={classes.inputBox} placeholder='Search...' />
            <Button
              variant='contained'
              color='primary'
              disableElevation={true}
              className={classes.btn}>
                <Search />
            </Button>
          </div> */}
        </div>

        {!data ? (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img src={clonedataEmpty} alt="" />
            <p style={{padding: "0 35%", textAlign: "center"}}>You have no data yet. Start tracking, monitoring, and managing with precision and efficiency today!</p>
          </div>
        ) : (
          <div className="table-responsive">
          <table className={classes.table}>
            <thead>
              <tr className={classes.tableHeaderRow}>
                <th className={classes.th}>ID Number</th>
                <th className={classes.th}>Transfer type</th>
                <th className={classes.th}>Source Organization</th>
                <th className={classes.th}>Destination Organization</th>
                <th className={classes.th}>Number of Apps</th>
                <th className={classes.th}>Date</th>
                <th className={classes.th}>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="7" className={classes.center}>
                    <CircularProgress size={24} />
                  </td>
                </tr>
              )}
              {!loading &&
                data &&
                data.map((item) => (
                  <tr key={item?.id} className={classes.tableBodyRow}>
                    <td className={classes.td}>{item?.id}</td>
                    <td className={classes.td}>
                      {item?.payload?.type.replaceAll("_", " ")}
                    </td>
                    <td className={classes.td}>
                      {item?.organization?.source_org_name}
                    </td>
                    <td className={classes.td}>
                      {item?.organization?.destination_org_name}
                    </td>
                    <td className={classes.td}>{item?.apps_count}</td>
                    <td className={classes.td}>
                      {dateFormatter(item?.created_at)}
                    </td>
                    <td className={classes.td}>
                      <Button
                        onClick={() => showLogs(item?.id)}
                        className={classes.btnWhiteText}
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        )}

        

        {!loading && data && pagination && (
          <div className={classes.pagination}>
            <button
              className={`${classes.paginationDataBtn} ${
                page === 1 && classes.opacity
              }`}
              type="button"
              disabled={page === 1}
              onClick={() => setPage((prev) => (prev > 1 ? prev - 1 : prev))}
            >
              <BsChevronLeft fontSize="12px" />
            </button>
            {Array.from({ length: pagination }).map((_, index) => (
              <button
                key={index}
                className={
                  index + 1 === page
                    ? classes.activePaginationData
                    : classes.paginationDataBtn
                }
                onClick={() => setPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`${classes.paginationDataBtn} ${
                page === pagination && classes.opacity
              }`}
              type="button"
              disabled={page === pagination}
              onClick={() =>
                setPage((prev) => (prev < pagination ? prev + 1 : prev))
              }
            >
              <BsChevronRight fontSize="12px" />
            </button>
          </div>
        )}
      </div>

      {open && <LogsModal {...{ open, handleClose }} id={log} />}
    </ModulesLayout>
  );
};

export default ActivityLog;
