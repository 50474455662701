/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import {
    Button,
    // CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";
import { acknowledgeTmail } from '../service';
import MyModal from "../../../molecule/Modal";
import ConnectModal from "../../../../containers/Connector/ConnectModal";
import Podio from "../../../../assets/images/podioconnect.png";
import { authorizeTmail, parseHashString } from "../../../../containers/Connector/sharedFunctions";
import { getMe } from "../../../../services/auth";
import { setUser } from "../../../../redux/actions/user";
import { authTmailConnect } from "../../../../helpers/Authentication/Auth";

const ConfirmConnector = ({ openModal, handleClose, page }) => {
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const closeAuthorizationModal = () => {
        setOpen(false);
    }
    const [selectedConnector] = useState({
        title: "Podio",
        image: Podio
    });

    const getUser = useCallback(async () => {
        const response = await getMe();
        setLoading(true)
        dispatch(setUser(response.data));
        setLoading(false)
    });

    const agreeTerms = async () => {
        setLoading(true)
        const resp = await acknowledgeTmail();
        if (resp === true) {
            getUser()
        }
        setLoading(false)
        return true
    }

    const [connecting, setConnecting] = useState({
        connector: null,
        loading: false,
    });

    const gotoPage = async () => {
        const resp = await agreeTerms();
        if (resp) {
            handleClose()
            setOpen(true)
        }

    }

    const makeCall = useCallback(
        async (access, auth_type) => {
            setLoading(true)
            const response = await authTmailConnect(access, auth_type);
            if (response) {
                getUser();
            }else{
                return history.push("/dashboard/sync"); 
            }
        },
        [getUser]
    );

    useEffect(() => {
        const auth_type = localStorage.getItem("tmail_auth_type");

        const hashString = window.location.hash.substr(1);

        if (hashString && auth_type === "podio") {
            const access = parseHashString(hashString);

            if (access["access_token"]) {
                access["user_id"] = access["ref[id]"];
                makeCall(access, auth_type);
                return history.push("/dashboard/tmail");
            }
        }
    }, [connecting, getUser, makeCall]);


    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography gutterBottom>
                                Your billing plan will be “Pay-As-You-Go” and will be billed separately from their sync subscription.<br />
                                Would you like to proceed?
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" onClick={handleClose} color="primary">
                            No
                        </Button>

                        <Button
                            id="button-save"
                            onClick={gotoPage}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <MyModal
                open={open}
                close={closeAuthorizationModal}
            >
                <ConnectModal
                    info={selectedConnector}
                    handleConnect={() =>
                        authorizeTmail(selectedConnector, setConnecting, location.pathname)
                    }
                    handleClose={closeAuthorizationModal}
                />
            </MyModal>
        </div>
    );
};

export default ConfirmConnector;
