import React, { useState, useEffect } from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import UserCard from "./UserCard";
import NewUserCard from "./NewUserCard";
import CustomButton from "../../containers/Website/widgets/buttons/Button";
import { changePlan, registerPlan } from "../../services/sync";
import plus from "../../assets/icons/plus.svg";
import styles from "./ManageCards.module.scss";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import SelectPaymentMethod from "./SelectPaymentMethod";
import checkGreen from "../../assets/icons/checkGreen.svg";
import wallet from "../../assets/icons/wallet.svg";
import infoIcon from "../../assets/icons/info.svg";
// import cancel from "../../assets/icons/cancel.svg";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";

const ManageCards = ({
  user,
  plansSection,
  loadCards,
  cardList,
  reloadStore,
  selectedPlan,
  active_plan,
  setSubscriptionStatus,
  setPaymentProcessing,
  setPlansSection,
  plansDescription,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [addPlanLoading, setAddPlanLoading] = useState(false);
  const [createNewCard, setCreateNewCard] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successModalLoader, setSuccessModalLoader] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();

  const handleSetSelectedCard = (cardId, checked) => {
    if (checked) {
      setSelectedCard(cardId);
    } else {
      setSelectedCard(null);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (successModal || successModalLoader) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else {
      // Re-enable scrolling
      document.body.style.overflow = "auto";
    }

    // Clean-up in case the modal is unmounted while open
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [successModal, successModalLoader]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  React.useEffect(() => {
    let defaultCard = null;
    if (cardList?.data?.length > 0) {
      defaultCard = cardList?.data?.find((card) => card?.make_default === 1);
    }
    if (defaultCard) {
      handleSetSelectedCard(defaultCard?.hidden_id, true);
    } else if (!defaultCard && cardList?.data?.length > 0) {
      handleSetSelectedCard(cardList?.data?.[0]?.hidden_id, true);
    }
  }, [cardList]);

  const servicesStarter = plansDescription[0] || {};
  const servicesBusiness = plansDescription[1] || {};
  const servicesEnterprice = plansDescription[2] || {};
  const servicesDataScientist = plansDescription[3] || {};
  const servicesWhiteGove = plansDescription[4] || {};

  const processPayment = async () => {
    window.scrollTo(0, 0);
    try {
      setAddPlanLoading(true);
      setSubscriptionStatus(true);
      setSuccessModalLoader(true);
      if (selectedCard) {
        let payload = {
          plan_id: selectedPlan.id,
          user_plan_id: active_plan === null ? false : active_plan?.plan_id,
          card_id: selectedCard,
        };
        if (active_plan) {
          await changePlan(payload);
          toaster.notify("Your plan was changed successfully.", {
            duration: "4000",
            position: "bottom",
          });
          reloadStore(true);
          setPaymentProcessing(true);
          setAddPlanLoading(false);
          // setPlansSection("confirmation");
          setSuccessModalLoader(false);
          setSuccessModal(true);
        } else {
          await registerPlan(payload);
          toaster.notify("You have purchased this successfully.", {
            duration: "4000",
            position: "bottom",
          });
          reloadStore(true);
          setPaymentProcessing(true);
          setAddPlanLoading(false);
          // setPlansSection("confirmation");
          setSuccessModalLoader(false);
          setSuccessModal(true);
        }
      }
    } catch (e) {
      setAddPlanLoading(false);
      setPaymentProcessing(false);
    }
    setSelectedCard(null);
  };

  const selectedCardDetails = cardList?.data?.find(
    (card) => card?.hidden_id === selectedCard
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const getMonthlyDates = () => {
      const current_date = new Date();
      const last_day_current_month = new Date(current_date);
      last_day_current_month.setDate(
        last_day_current_month.getDate() +
          (last_day_current_month.getMonth() === 1
            ? 28
            : last_day_current_month.getMonth() === 3 ||
              last_day_current_month.getMonth() === 5 ||
              last_day_current_month.getMonth() === 8 ||
              last_day_current_month.getMonth() === 10
            ? 30
            : 31)
      );

      setStartDate(current_date);
      setEndDate(last_day_current_month);
    };

    getMonthlyDates();
  }, []);

  return (
    <>
      {isOpen ? (
        <SelectPaymentMethod
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          cardList={cardList}
          handleSetSelectedCard={handleSetSelectedCard}
          selectedCard={selectedCard}
        />
      ) : null}

      {successModal && (
        <div className="modal_bg">
          <div className="modal_cont">
            {/* <div className="d-flex justify-content-end">
              <img
                onClick={() => setSuccessModal(false)}
                width="7%"
                src={cancel}
                alt=""
              />
            </div> */}
            <div className="d-flex justify-content-center mt-3">
              <img width="10%" src={infoIcon} alt="" />
            </div>
            <div style={{ textAlign: "center", marginBottom: "5%" }}>
              <h5 className="mb-4 bold">Payment successful</h5>
              <p className="pl-2 pr-2">
                Your payment was successful. You can now access exclusive
                feature on your subscription plan by clicking on the button.
              </p>
              <button
                style={{
                  width: "60%",
                  height: "50px",
                  backgroundColor: "#f0b429",
                  border: "none",
                  marginTop: "5%",
                  color: "white",
                  borderRadius: "4px",
                }}
                onClick={() => history.push("/dashboard/sync")}
              >
                Yes, Proceed
              </button>
              {/* <CircularProgress color="info" size={20} /> */}
            </div>
          </div>
        </div>
      )}

      {successModalLoader && (
        <div className="modal_bg">
          <div className="modal_cont">
            <img width="10%" src={infoIcon} alt="" />
            <div style={{ textAlign: "center", marginBottom: "5%" }}>
              <h5 className="mb-4">Processing Payment</h5>
              <CircularProgress color="info" size={20} />
            </div>
          </div>
        </div>
      )}

      {plansSection === "payment" && (
        <div
          style={isMobile ? { marginTop: "50px" } : { marginTop: "150px" }}
          className="payment"
        >
          <div className="container">
            {!createNewCard ? (
              <div className={styles.wrapper}>
                <button
                  className={styles.select_payment_card}
                  onClick={() => setIsOpen(true)}
                >
                  Select Payment Method
                  <span>
                    <KeyboardArrowDown />
                  </span>
                </button>
                {selectedCardDetails ? (
                  <div className={styles.payment_cards_container}>
                    <UserCard selectedCardDetails={selectedCardDetails} />
                  </div>
                ) : null}
                <CustomButton
                  text="Add New Card"
                  img={plus}
                  styling="bg-bd5-5-fx font-12 button-4 mt-2 full-input mb-3"
                  function={() => setCreateNewCard(!createNewCard)}
                />
              </div>
            ) : null}
            {createNewCard ? (
              <NewUserCard
                user={user}
                loadCards={loadCards}
                setCreateNewCard={setCreateNewCard}
              />
            ) : (
              ""
            )}
            <div>
              <div className="card2 plan-details">
                <div className="head">
                  <div className="bold">Summary</div>
                </div>
                {selectedPlan && (
                  <div className="plan-details-container font-12">
                    <div
                      style={
                        isMobile ? { fontSize: "14px" } : { fontSize: "16px" }
                      }
                      className="items-container"
                    >
                      <div className="item">
                        <span>Plan</span>
                        <span className="bold">{selectedPlan?.name}</span>
                      </div>

                      <div className="item">
                        <span>Amount</span>
                        <span className="bold">
                          {selectedPlan.currency}${selectedPlan.price}/Month
                        </span>
                      </div>

                      <div className="item">
                        <span>Start date</span>
                        <span className="bold">
                          {startDate?.toLocaleDateString()}
                        </span>
                      </div>

                      <div className="item">
                        <span>End Date</span>
                        <span className="bold">
                          {endDate?.toLocaleDateString()}
                        </span>
                      </div>

                      {/* <div className="item">
                              <span>
                                {description?.orgs}{" "}
                                {description?.orgs === 1
                                  ? "Organisation"
                                  : "Organisations"}
                              </span>
                            </div>

                            <div className="item">
                              <span>
                                {meta?.connection}{" "}
                                {meta?.connection === 1
                                  ? "Connection"
                                  : "Connections"}
                              </span>
                            </div> */}

                      <div>
                        <p className="bold">Services</p>
                        {selectedPlan?.name === "Starter" ? (
                          <div>
                            {Object.keys(servicesStarter || {}).map(
                              (key, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1"
                                >
                                  <img src={checkGreen} alt="" />
                                  <p className="ml-2">
                                    {" "}
                                    {servicesStarter[key]?.toString()}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : selectedPlan?.name === "Business" ? (
                          <div>
                            {Object.keys(servicesBusiness || {}).map(
                              (key, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1"
                                >
                                  <img src={checkGreen} alt="" />
                                  <p className="ml-2">
                                    {" "}
                                    {servicesBusiness[key]?.toString()}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : selectedPlan?.name === "Enterprise" ? (
                          <div>
                            {Object.keys(servicesEnterprice || {}).map(
                              (key, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1"
                                >
                                  <img src={checkGreen} alt="" />
                                  <p className="ml-2">
                                    {" "}
                                    {servicesEnterprice[key]?.toString()}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : selectedPlan?.name === "Data Scientist" ? (
                          <div>
                            {Object.keys(servicesDataScientist || {}).map(
                              (key, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1"
                                >
                                  <img src={checkGreen} alt="" />
                                  <p className="ml-2">
                                    {" "}
                                    {servicesDataScientist[key]?.toString()}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : selectedPlan?.name === "White Glove" ? (
                          <div>
                            {Object.keys(servicesWhiteGove || {}).map(
                              (key, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1"
                                >
                                  <img src={checkGreen} alt="" />
                                  <p className="ml-2">
                                    {" "}
                                    {servicesWhiteGove[key]?.toString()}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="items-container font-18 bold">
                      <div style={{ color: "#DE911D" }} className="item">
                        <span>Total</span>
                        <span>${selectedPlan.price}</span>
                      </div>
                    </div>
                    <div className="items-container">
                      <div className="item color-9"></div>
                    </div>
                    <div className="item">
                      <CustomButton
                        text={addPlanLoading ? "Please wait..." : "Subscribe"}
                        img={wallet}
                        disable={
                          !selectedPlan ||
                          selectedCard === null ||
                          addPlanLoading ||
                          isChecked === false
                            ? true
                            : false
                        }
                        styling={
                          !selectedPlan ||
                          selectedCard === null ||
                          isChecked === false
                            ? "bg-bd6-6 font-12 button-4 full-input"
                            : "bg-bd2-2 font-12 button-4 full-input"
                        }
                        function={() => processPayment()}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  border: "1px solid #F2B11B",
                  backgroundColor: "#FFFBF1",
                  padding: "3%",
                  borderRadius: "10px",
                }}
                className="mt-3"
              >
                <p>
                  Please note that you will be charged a recurring monthly fee
                  of ${selectedPlan.price}. To cancel the plan, kindly read our
                  plan{" "}
                  <a
                    style={{ color: "#000", borderBottom: "1px solid black" }}
                    href="https://help.thatapp.io/en/articles/9867786-pricing-policy"
                  >
                    cancellation policy.
                  </a>
                </p>
              </div>

              <div className="mt-3 d-flex align-items-start mb-5">
                <input
                  style={{
                    appearance: "auto",
                    width: "24px",
                    marginRight: "3%",
                    marginTop: "1%",
                    height: "auto",
                    accentColor: "#F7C948",
                  }}
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <p>
                  By checking this box, you are agreeing to our{" "}
                  <a style={{ color: "#F7C948" }} href="/pricing">
                    Pricing
                  </a>
                  ,{" "}
                  <a
                    style={{ color: "#F7C948" }}
                    href="https://help.thatapp.io/en/articles/108584-privacy-policy"
                  >
                    Privacy Policy{" "}
                  </a>
                  and{" "}
                  <a
                    style={{ color: "#F7C948" }}
                    href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
                  >
                    Terms of service
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageCards;
