import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Box,
} from "@material-ui/core";
import SignatureModal from "./SignatureModal";

const SignatureListModal = (props) => {
   const { openModal, handleClose, signatures, appId, handleSignatureChange, openFirstModal } = props;
    const [open, setOpen] = useState(false);
    const [signature, setSignature] = useState(null);

    const openAddModal = (id) => {
        handleClose();
        setOpen(true)
        const sign = signatures.find(signa => signa.id === id);
        setSignature(sign);
    }
    const closeAddModal = () => {
        setOpen(false)
        openFirstModal()
    }

    return (
        <div>
            <SignatureModal
                openModal={open}
                handleClose={closeAddModal}
                signatureData={signature}
                signatures={signatures}
                appId={appId}
                handleSignatureChange={handleSignatureChange}
            />
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableBackdropClick={false}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
                fullWidth="700px"
            >
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            <Typography gutterBottom>
                                <Box>

                                    <div className="card-body">
                                        <h4 className="text-center">Signatures</h4>
                                        <ul className="list-group">
                                            {
                                                signatures.length > 0 ? signatures.map(sign => (
                                                    <li
                                                        key={sign.name}
                                                        className="list-group-item"
                                                        onClick={() => openAddModal(sign.id)}
                                                    >{sign.name}</li>
                                                )) : null
                                            }

                                        </ul>
                                    </div>
                                    <div >
                                        <button
                                            type='button' style={{
                                                color: "#fff",
                                                background: "#F7C948",
                                            }}
                                            onClick={openAddModal}
                                            className="btn btn-default">New Signature</button>
                                    </div>
                                </Box>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SignatureListModal;
