import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { formatNumber } from 'utils/formatNumber';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  spaceItem: {
    height: '64px',
    width: '100%',
    backgroundColor: '#F7F7F7',
    marginBottom: '16px',
    padding: '23px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px',
    color: '#222',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'uppercase'
  },
  btn: {
    fontSize: '19px',
    color: '#E10000 !important',
    backgroundColor: 'transparent',
    border: 'none',
    height: 'fit-content',
    width: 'fit-content'
  },
  container: {
    display: 'flex',
    gap: '73px',
  },
  cartItems: {
    width: '734px'
  },
  checkoutBox: {
    width: '203px'
  },
  checkoutTitle: {
    color: "#25252D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "-0.32px",
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    padding: '20px 0px',
    marginBottom: '5px'
  },
  priceBox1: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    borderTop: '1px solid #E0E0E0',
    padding: '20px 0px',
    marginBottom: '5px'
  },
  price: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  priceTextStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: '0px'
  },
  btnContainer: {
    marginTop: '30px'
  },
  btnWhiteText: {
    backgroundColor: '#F0B429',
    borderRadius: '3px',
    textTransform: 'capitalize',
    padding: '12px 32px',
    color: "#222 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    width: '100%'
  },
  spaceItemDetails: {
    fontSize: '15px',
    color: "#535c68 !important",
    textTransform: 'capitalize'
  }
}));

const CheckoutCardV2 = ({ responseData, onClick, btnTitle, loading, disabled  }) => {
  const classes = useStyles();

  return (
    <div className={classes.checkoutBox}>
      <h5 className={classes.checkoutTitle}>Order Summary</h5>
      <div className={classes.priceBox1}>
        { responseData.hasOwnProperty('spaces_count') ? <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Total Space</h6>
          <p className={classes.priceTextStyle}>{responseData.spaces_count}</p>
        </div> : <div></div> }
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Total Apps</h6>
          <p className={classes.priceTextStyle}>{responseData.apps_count}</p>
        </div>
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Total Files</h6>
          <p className={classes.priceTextStyle}>{responseData.files_count}</p>
        </div>
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Total Items</h6>
          <p className={classes.priceTextStyle}>{responseData.items_count}</p>
        </div>
      </div>
      <div className={classes.priceBox}>
        {/* <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Items Cost</h6>
          <p className={classes.priceTextStyle}>${formatNumber(responseData.items_cost)}</p>
        </div>
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Files Cost</h6>
          <p className={classes.priceTextStyle}>${formatNumber(responseData.files_cost)}</p>
        </div> */}
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Cost per record</h6>
          <p className={classes.priceTextStyle}>${formatNumber(responseData.unit_item_cost)}</p>
        </div>
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Subtotal</h6>
          <p className={classes.priceTextStyle}>${
            responseData.hasOwnProperty('subtotal') ? 
            formatNumber(responseData?.subtotal) : 
            formatNumber(responseData?.items_cost + responseData?.files_cost)}
          </p>
        </div>
        <div className={classes.price}>
          <h6 className={classes.priceTextStyle}>Tax</h6>
          <p className={classes.priceTextStyle}>${formatNumber(responseData.taxes_cost)}</p>
        </div>
      </div>
      <div className={classes.price}>
        <h6 className={classes.priceTextStyle}>Total</h6>
        <p className={classes.priceTextStyle}>${
          responseData.hasOwnProperty('subtotal') ?
          formatNumber(responseData.subtotal + responseData.taxes_cost) : 
          formatNumber(responseData?.items_cost + responseData?.files_cost + responseData.taxes_cost)
        }</p>
      </div>

      <div className={classes.btnContainer}>
        {loading ? 
          <CircularProgress size={24} />
        : <Button
          variant='contained'
          color='primary'
          disableElevation={true}
          onClick={onClick}
          className={classes.btnWhiteText}
          disabled={disabled}

        >{btnTitle}</Button>}
      </div>
    </div>
  )
}

export default CheckoutCardV2
