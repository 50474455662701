import React from 'react'
// widgets
import Button from '../widgets/buttons/Button'
// style
import './style/get-started.scss'
import './style/general.scss'

const GetStarted = e => {
    return <section className="get-started">
        <div className={`container ${e.bg}`}>
            <div className="inner">
                <div className={`font-32 bold ${e.color}`}>Ready to get started?</div>
                <div className="text">Let us bring you up to speed on automation, syncing, and gaining insight on your data</div>
                <div>
                    <Button 
                        text="Get started"
                        styling={e.btnBg}
                        id={e.id}
                        function={e.function}
                    />
                </div>
            </div>
        </div>
    </section>
}

export default GetStarted
