import React, { useEffect, useState } from "react";
import "./PlansAccount.scss";
import "./general.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector } from "react-redux";
import { toast } from "helpers/apiRequests";
import CustomButton from "../../containers/Website/widgets/buttons/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
// icons
import check2 from "../../containers/Website/icons/check-mark2.svg";
import check4 from "../../assets/icons/checkbox.svg";
import arrow from "../../assets/icons/arrow-down2.svg";
import check3 from "../../containers/Website/icons/check-mark3.svg";
import dash from "../../containers/Website/icons/dash.svg";
import Logo from "../../assets/images/logo.svg";
import featureIcon from "../../assets/icons/featureIcon.svg";
import blueCheck from "../../assets/icons/blueCheck.svg";
import thatapp from "../../assets/icons/thatapp.svg";
import payment_successful from "../../assets/images/payment-successful.gif";
import useWindowSize from "hooks/useWindowSize";
import ManageCards from "./ManageCards";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    position: "absolute",
    top: "0",
    width: "100%",
    padding: "0",
    overflowX: "scroll",
    height: "100vh",
  },
  bgGoldButton: {
    background: "#F7C948",
    color: "#fff",
    width: "100%",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

const PlansAccount = ({ user, plans, cardList, loadCards, reloadStore }) => {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // const section = urlParams.get("section");
  const redirectRoute = urlParams.get("redirect");
  // const message = urlParams.get("message");

  const handleOpen = () => {
    setOpen(true);
  };

  const invitation = useSelector((state) => state.user.invite);
  const active_plan = user?.active_plan;
  const permission = user?.permissions;

  const handleClose = () => {
    setOpen(false);
    if (redirectRoute) {
      window.location.href = redirectRoute;
    }
  };
  console.log(selectedPlan);
  // useEffect(() => {
  //   if (section === "plans") {
  //     setOpen(true);
  //   }

  //   if (message && message === "unsupported-plan") {
  //     toast.notify(
  //       "Upgrade your current plan to be able to use more features.",
  //       { type: "info" }
  //     );
  //   }
  // }, [message, section]);

  useEffect(() => {
    if (invitation.processing) {
      setOpen(false);
    }
  }, [invitation.processing]);

  const [allFeatures, setAllFeatures] = useState(false);
  const [plansSection, setPlansSection] = useState("choose");

  const handlePlanSection = (planSection) => {
    if (
      plansSection === "confirmation" &&
      paymentProcessing &&
      planSection !== "confirmation"
    ) {
      return;
    }

    if (
      (planSection === "payment" || planSection === "confirmation") &&
      isEmpty(selectedPlan)
    ) {
      toast.notify("You have to select a plan first.", { type: "info" });
      return;
    }

    if (!subscriptionStatus && planSection === "confirmation") {
      toast.notify("You need to make payment for a service first", {
        type: "info",
      });
      return;
    }
    setPlansSection(planSection);
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const handleSelectPlan = (planId) => {
    if (active_plan?.plan_id === planId) {
      toast.notify("You are already subscribed to this plan.", {
        type: "info",
      });
      setSelectedPlan({});
      return;
    }
    setPlansSection("payment");
  };

  const plansDescription = plans?.map((item) => item?.description_details);

  return (
    <div className="plans-account">
      <div style={isMobile ? { padding: "0" } : { padding: "1rem" }}>
        <div>
          {active_plan ? (
            <div
              style={
                isMobile
                  ? { flexDirection: "column", height: "70vh" }
                  : { flexDirection: "column", height: "70vh" }
              }
              className="d-flex justify-content-between"
            >
              <div>
                <h5 className="bold">My Subscription Plan</h5>
                <div
                  style={isMobile ? { flexDirection: "column" } : null}
                  className="d-flex align-items-center justify-content-between mt-3"
                >
                  <div
                    style={
                      isMobile
                        ? {
                            width: "100%",
                            alignItems: "flex-start",
                          }
                        : {
                            width: "48%",
                            alignItems: "flex-start",
                          }
                    }
                    className="d-flex"
                  >
                    <div
                      style={
                        isMobile
                          ? {
                              width: "100%",
                              backgroundColor: "#F9FDFF",
                              border: "1px solid #5ED0FA",
                              borderRadius: "5px",
                              padding: "3%",
                            }
                          : {
                              backgroundColor: "#F9FDFF",
                              border: "1px solid #5ED0FA",
                              borderRadius: "5px",
                              padding: "3%",
                              width: "100%",
                            }
                      }
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5 className="bold">{active_plan?.planName}</h5>
                          <p>{active_plan?.days_left} days remaining</p>
                        </div>

                        <div>
                          <p>
                            <span
                              style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                              ${active_plan?.plan?.price}
                            </span>
                            /month
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-4 align-items-center">
                        <div>
                          <p>Feature</p>
                          <div className="d-flex align-items-center">
                            <img width="20px" src={featureIcon} alt="" />
                            {active_plan?.planName === "Starter" ? (
                              <p>1 Organisations</p>
                            ) : active_plan?.planName === "Business" ? (
                              <p>2 Organisations</p>
                            ) : active_plan?.planName === "Enterprise" ? (
                              <p>4 Organisations</p>
                            ) : active_plan?.planName === "Data Scientist" ? (
                              <p>10 Organisations</p>
                            ) : active_plan?.planName === "White Glove" ? (
                              <p>15 Organisations</p>
                            ) : null}
                          </div>
                        </div>

                        <div
                          style={{
                            width: "45%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "#F0FBFF",
                              color: "#40C3F7",
                              borderRadius: "30px",
                              border: "1px solid #40C3F7",
                              fontSize: "16px",
                              height: "45px",
                              padding: "5% 10%",
                              cursor: "default",
                            }}
                          >
                            Current plan
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "-5px", marginLeft: "-10px" }}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <img src={blueCheck} alt="" />
                    </div>
                  </div>

                  {active_plan?.planName === "White Glove" ? null : (
                    <div>
                      {isMobile && (
                        <div className="mt-3 mb-3">
                          <p>Next upgradable plan</p>
                        </div>
                      )}
                    </div>
                  )}

                  {active_plan?.planName === "White Glove" ? null : (
                    <div
                      style={
                        isMobile
                          ? {
                              width: "100%",
                              backgroundColor: "#2B79ED",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3%",
                            }
                          : {
                              backgroundColor: "#2B79ED",
                              color: "white",
                              borderRadius: "5px",
                              padding: "2%",
                              width: "48%",
                            }
                      }
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          {active_plan?.planName === "Starter" ? (
                            <h5 className="bold">Business</h5>
                          ) : active_plan?.planName === "Business" ? (
                            <h5 className="bold">Enterprise</h5>
                          ) : active_plan?.planName === "Enterprise" ? (
                            <h5 className="bold">Data Scientist</h5>
                          ) : active_plan?.planName === "Data Scientist" ? (
                            <h5 className="bold">White Glove</h5>
                          ) : null}
                          <p>30 days</p>
                        </div>

                        <div>
                          {active_plan?.planName === "Starter" ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                $60
                              </span>
                              /Month
                            </p>
                          ) : active_plan?.planName === "Business" ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                $100
                              </span>
                              /Month
                            </p>
                          ) : active_plan?.planName === "Enterprise" ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                $250
                              </span>
                              /Month
                            </p>
                          ) : active_plan?.planName === "Data Scientist" ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                $400
                              </span>
                              /Month
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-4 align-items-center">
                        <div>
                          <button
                            style={{
                              backgroundColor: "white",
                              color: "#40C3F7",
                              borderRadius: "30px",
                              border: "none",
                              fontSize: "16px",
                              height: "40px",
                              padding: "5% 25%",
                            }}
                            onClick={handleOpen}
                          >
                            Upgrade
                          </button>
                        </div>

                        <div style={{ width: "60%", textAlign: "right" }}>
                          <p>Learn more about this plan</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* <img src={Logo} alt="logo" /> */}
                <span className="info">
                  {/* <div>{active_plan?.planName}</div> */}
                  <button
                    style={{
                      border: "1px solid #F0B429",
                      color: "#F0B429",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                    }}
                    className="highlighted mt-5"
                    onClick={handleOpen}
                  >
                    See Other Plans
                  </button>
                </span>
              </div>

              <div
                style={
                  isMobile
                    ? { width: "100%", fontSize: "14px", marginBottom: "5%" }
                    : { width: "90%", fontSize: "18px" }
                }
              >
                <p>
                  When you cancel your subscription, you will lose access to all
                  premium features that come with your plan. Click here to read
                  our pricing policy
                </p>
                <p
                  style={{
                    color: "#EF4E4E",
                    borderBottom: "1px solid",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.Intercom(
                      "showNewMessage",
                      `Hello. I would like to cancel my ${active_plan?.planName} subscription.`
                    );
                  }}
                  className="mt-3"
                >
                  Cancel Subscription
                </p>
              </div>
            </div>
          ) : (
            <>
              <img src={Logo} alt="logo" />
              <span className="info">
                <div>You have no active plan yet</div>
                <div className="highlighted" onClick={handleOpen}>
                  Check out the Plans with upgrade button
                </div>
              </span>
            </>
          )}
        </div>
        {/* <div className="right">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "4rem" }}
            onClick={handleOpen}
          >
            {permission ? "Upgrade" : "Select Plan"}
          </Button>
        </div> */}
      </div>

      {/* {permission && (
        <div className="body">
          <div className="small">The features of your current plan are</div>
          <div className="list">
            <div>
              No. orgs: <b>{permission?.orgs}</b>
            </div>
            <div>
              Iris:{" "}
              <b>
                {permission?.iris ? "Available" : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Audit:{" "}
              <b>
                {permission?.audit ? "Available" : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Restore:{" "}
              <b>
                {permission.restore
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Live Data:{" "}
              <b>
                {permission.live_data
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Data Mesh:{" "}
              <b>
                {permission.data_mesh
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              App Builder:{" "}
              <b>
                {permission.app_builder
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              File Haven:{" "}
              <b>
                {permission.file_haven
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
          </div>
        </div>
      )} */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={`${classes.paper} plans-account-modal pricing-pricing`}
          >
            <div className="inner-account-modal">
              <div className="header">
                <img src={thatapp} alt="ThatApp" className="logo" />
                <div className="modal-navigation">
                  <RenderPlanHeader selected={plansSection === "choose"}>
                    <span
                      onClick={() => handlePlanSection("choose")}
                      className={plansSection === "choose" ? "active" : ""}
                    >
                      1. Choose a plan
                    </span>
                  </RenderPlanHeader>
                  <RenderPlanHeader selected={plansSection === "payment"}>
                    <span
                      onClick={() => handlePlanSection("payment")}
                      className={plansSection === "payment" ? "active" : ""}
                    >
                      2. Payment
                    </span>
                  </RenderPlanHeader>
                  {/* <RenderPlanHeader selected={plansSection === "confirmation"}>
                    <span
                      onClick={() => handlePlanSection("confirmation")}
                      className={
                        plansSection === "confirmation" ? "active" : ""
                      }
                    >
                      3. Confirmation
                    </span>
                  </RenderPlanHeader> */}
                </div>
                <span onClick={handleClose} className="close">
                  &times;
                </span>
              </div>
              <div className="modal-container">
                {plansSection === "choose" && (
                  <div className="plans">
                    <div className="plans-wrapper">
                      <div className="plans-container">
                        {plans.map((plan, index) => {
                          let description = JSON.parse(plan.description);
                          let meta = JSON.parse(plan.meta);

                          return (
                            <div
                              className={
                                active_plan?.plan_id === plan.id
                                  ? "item active-plan"
                                  : selectedPlan?.id === plan.id
                                  ? "item item-active"
                                  : "item"
                              }
                              key={index}
                            >
                              <div className="content">
                                <div className="font-22 bolder name">
                                  {plan.name}
                                </div>
                                {/* <p className="font-14">
                                    For entrepreneurs and teams to start selling smarter.
                                  </p> */}
                                <div className="color-1 price2">
                                  <span className="font-34">${plan.price}</span>
                                  <span className="font-24">/Month</span>
                                </div>
                                <button
                                  className={`cta ${
                                    selectedPlan.id === plan.id
                                      ? "bg-bd6-6-fx button-4"
                                      : "bg-bd5-5-fx button-4"
                                  }`}
                                  onClick={() => {
                                    setSelectedPlan(plan);
                                    handleSelectPlan(plan.id);
                                  }}
                                >
                                  {selectedPlan?.id === plan.id
                                    ? "Selected"
                                    : plan.text}
                                </button>
                                <div className="features">
                                  <div>
                                    <img
                                      src={
                                        active_plan?.plan_id === plan.id
                                          ? check4
                                          : check2
                                      }
                                      alt={
                                        meta?.sync?.organisations
                                          ? "check"
                                          : "dash"
                                      }
                                    />{" "}
                                    {description?.orgs === 1 && (
                                      <span>
                                        {description?.orgs} Organisation
                                      </span>
                                    )}
                                    {description?.orgs > 1 && (
                                      <span>
                                        {description?.orgs} Organisations
                                      </span>
                                    )}
                                  </div>
                                  {/* <div>
                                      <img src={active_plan?.plan_id === plan.id ? check4 : check2} alt={meta?.sync?.organisations ? "check" : "dash"} />{" "}
                                      <span>{meta?.connection} Connection{meta?.connection > 1 && "s"}</span>
                                    </div> */}
                                </div>
                                <div className="see-more color-1"></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      onClick={() => setAllFeatures(!allFeatures)}
                      className={
                        allFeatures
                          ? "all-features-navigation bg-11 container-container all-features-navigation-active text-center"
                          : "bg-11 container-container all-features-navigation text-center"
                      }
                    >
                      See all Features <img src={arrow} alt="arrow" />
                    </div>
                    <div
                      className={
                        allFeatures
                          ? "container-container all-features all-features-active"
                          : "container-container all-features"
                      }
                    >
                      <div className="plan-details container-container">
                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">SYNC</div>
                                <div className="color-black font-20">
                                  Features
                                </div>
                              </div>
                              <div className="tab">Organisations</div>
                              <div className="tab">Space</div>
                              <div className="tab">App</div>
                              <div className="tab">Item</div>
                              <div className="tab">Revisions</div>
                              <div className="tab">Comments</div>
                              <div className="tab">Hooks</div>
                              <div className="tab">Contacts</div>
                              <div className="tab">Tasks</div>
                              <div className="tab">Files</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.organisations
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.spaces ? check3 : dash}
                                        alt={
                                          meta?.sync?.spaces ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.apps ? check3 : dash}
                                        alt={
                                          meta?.sync?.apps ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.items ? check3 : dash}
                                        alt={
                                          meta?.sync?.items ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.revisions ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.revisions
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.comments ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.comments
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.hooks ? check3 : dash}
                                        alt={
                                          meta?.sync?.hooks ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.contacts ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.contacts
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.tasks ? check3 : dash}
                                        alt={
                                          meta?.sync?.tasks ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.files ? check3 : dash}
                                        alt={
                                          meta?.sync?.files ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Data Interaction
                                </div>
                              </div>
                              <div className="tab">Item Creation</div>
                              <div className="tab">Custom Filters</div>
                              <div className="tab">Row Grouping</div>
                              <div className="tab">Pivot Reporting</div>
                              <div className="tab">Real time Data</div>
                              <div className="tab">Data Export</div>
                              <div className="tab">Saved Views</div>
                              <div className="tab">Cell Editing</div>
                              <div className="tab">Nested Files</div>
                              <div className="tab">Sync Charts</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);

                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.item_creation
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.custom_filters
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.row_grouping
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction
                                            ?.pivot_reporting
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.real_time_data
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.data_export
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.saved_views
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.cell_editing
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.nested_files
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.sync_charts
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    onClick={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {selectedPlan.id === plan.id
                                      ? "Selected"
                                      : plan.text}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Data Restoration
                                </div>
                              </div>
                              <div className="tab">Items</div>
                              <div className="tab">Comments</div>
                              <div className="tab">Tasks</div>
                              <div className="tab">Files</div>
                              <div className="tab">App Fields</div>
                              <div className="tab">Applications</div>
                              <div className="tab">Workspaces</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.items
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.items
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.comments
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.comments
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.tasks
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.tasks
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.files
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.files
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.app_fields
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.app_fields
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.applications
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.applications
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.workspaces
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.workspaces
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">Tools</div>
                              </div>
                              <div className="tab">GDPR Settings</div>
                              <div className="tab">Hook Killer</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.tools?.gdpr_settings
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.tools?.gdpr_settings
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.tools?.hook_killer
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.tools?.hook_killer
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">AUDIT</div>
                              </div>
                              <div className="tab">Queue Management</div>
                              <div className="tab">User Management</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.audit?.queue_management
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.audit?.queue_management
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.audit?.user_management
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.audit?.user_management
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Event Logging
                                </div>
                              </div>
                              <div className="tab">Creation</div>
                              <div className="tab">Updates</div>
                              <div className="tab">Deletes</div>
                              <div className="tab">Shared Items</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.creation
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.creation
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.updates
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.updates
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.deletes
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.deletes
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.shared_items
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.shared_items
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">
                                  FILE HAVEN
                                </div>
                              </div>
                              <div className="tab">Logic Builder</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.file_haven?.logic_builder
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.file_haven?.logic_builder
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">IRIS GENESIS</div>
                              </div>
                              <div className="tab">Queue Management</div>
                              <div className="tab">User Management</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {
                              plans.map((plan, index) => {
                                let meta = JSON.parse(plan.meta);
                                return (
                                  <div className="item" key={index}>
                                    <div className="inner">
                                      <div className="tab-header">
                                        <div className="color-black font-20 bold">
                                          {plan.name}
                                        </div>
                                        <div>{plan.currency}{plan.price} / Month</div>
                                      </div>
                                      <div className="tab">
                                        <img src={meta?.iris_genesis?.queue_management ? check3 : dash} alt={meta?.iris_genesis?.queue_management ? "check" : "dash"} />
                                      </div>
                                      <div className="tab">
                                        <img src={meta?.iris_genesis?.user_management ? check3 : dash} alt={meta?.iris_genesis?.user_management ? "check" : "dash"} />
                                      </div>
                                    </div>
                                    <CustomButton
                                      text={selectedPlan.id === plan.id ? "Selected" : plan.text}
                                      styling={active_plan?.plan_id === plan.id ? "disabled bg-bd6-6-fx button-4" : (
                                        selectedPlan.id === plan.id ? "bg-bd6-6-fx button-4" :
                                          "bg-bd5-5-fx button-4")}
                                      function={() => {
                                        setSelectedPlan(plan);
                                        handleSelectPlan(plan.id)
                                      }}
                                    />
                                  </div>
                                );
                              })
                            }

                          </div>
                        </div> */}

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">PRINT</div>
                              </div>
                              <div className="tab">Templates</div>
                              <div className="tab">Production</div>
                              <div className="tab">Development</div>
                              <div className="tab">JSON</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.templates}
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.production}
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.development}
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.print?.json ? check3 : dash}
                                        alt={
                                          meta?.print?.json ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">AVA</div>
                              </div>
                              <div className="tab">Workspaces</div>
                              <div className="tab">Production</div>
                              <div className="tab">Development</div>
                              <div className="tab">Git Repo</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.workspaces}
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.production}
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.development}
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.ava?.git_repo ? check3 : dash
                                        }
                                        alt={
                                          meta?.ava?.git_repo ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <ManageCards
                  {...{
                    user,
                    plansSection,
                    loadCards,
                    cardList,
                    reloadStore,
                    selectedPlan,
                    active_plan,
                    setSubscriptionStatus,
                    setPaymentProcessing,
                    setPlansSection,
                    permission,
                    plansDescription,
                  }}
                />

                {plansSection === "confirmation" ? (
                  paymentProcessing ? (
                    <div className="confirmation">
                      <div className="container">
                        <img src={payment_successful} alt="Successful" />
                        <div className="font-24 bold">All done!</div>
                        <div className="font-20 color-10">
                          You’ve successfully subscribed to a{" "}
                          {selectedPlan?.name} (Monthly) Plan
                        </div>
                        <CustomButton
                          text="Continue to dashboard"
                          styling="bg-bd2-2 font-12 button-4"
                          function={handleClose}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PlansAccount;

function RenderPlanHeader({ selected, children }) {
  const { width } = useWindowSize();
  const isMobile = width < 650;

  if (!isMobile) return children;

  if (isMobile && selected) {
    return children;
  } else {
    return null;
  }
}
