import React, { useState } from "react";
import "../index.css";
import Logo from "../../../assets/images/logosync.svg";
import bg from "../../../assets/images/authbg.png";
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from "../../../services/auth";
import Asterisk from "../../../components/Common/Asterisk";
import { toast } from "helpers/apiRequests"


const ResetPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
      password: "",
      confirmPassword: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
        toast.notify("Please input the same value in both fields", { type: "info" });
          return;
    }
    setLoading(true)
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const token = url.searchParams.get('token');

    const object = {
        email,
        token,
        password: data.password
    }
    try {
      let response = await resetPassword(object)
      if (response.message) {
        toast.notify(response.message);
      }
      history.push("/login")
      setLoading(false)
    } catch(err) {
    setLoading(false)
    }
    setData({
      password: "",
      confirmPassword: ""
    });
  };

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="auth__sidebar" style={{
                backgroundImage: `url(${bg})`,
                backgroundSize:`cover`,
                backgroundPosition:`center`
            }}>
            <div className="layer">
              <div className="custom__container__80">
                <img src={Logo} alt="logo" />
                <h1 className="title">
                  Welcome to <br /> ThatApp!
                </h1>
                <p className="description">
                  It is our job to ensure that you are never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="text-right">
                <p className="route__text">
                  Remember Password?{" "}
                  <Link className="link__text" to="/login">
                    Sign in
                  </Link>
                </p>
              </div>
              <div className="auth__form__container mt-5">
                <h1 className="pt-5">Reset Password?</h1>
                <p>
                  Enter the email address of the organisation you belong to and
                  we’ll send you instructions to reset your password.
                </p>
                <form onSubmit={handleSubmit} className="mt-3">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Password <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        name="password"
                        value={data.password}
                        required
                        type="password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label>
                       Confirm Password <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        name="confirmPassword"
                        value={data.confirmPassword}
                        required
                        type="password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <button className="btn auth-btn" disabled={loading}>
                    {loading ? "Please Wait" : "Reset Password"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
