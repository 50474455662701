import React from "react";
import Logo from "../../assets/images/logoyellow.svg";
import Ava from "../../assets/images/avaservice.svg";
import Sync from "../../assets/images/syncservice.svg";
import Print from "../../assets/images/printio.png";
// import Tmail from "../../assets/images/logoTrans2.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ChooseService.css";
import CustomButton from "../../components/atoms/CustomButton";
import { logout } from "../../redux/actions/auth";
import RenderByPermission from "components/RenderByPermission";

const ChooseService = () => {
  const user = useSelector((state) => state.user.authUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const [synclink, setSynclink] = React.useState("/connect");
  const [printLink, setPrintLink] = React.useState("/connect-print");

  React.useEffect(() => {
    if (user && user.podio_id !== null) {
      setSynclink("/dashboard/sync");
    }

    if (user && user.print_connection) {
      setPrintLink("/print-home");
    }
  }, [user]);

  const cards = [
    {
      image: Sync,
      text: "Backup and Protect your",
      text2: "Sensitive Data!",
      onClick: () => (window.location.href = synclink),
      resize: false,
    },
    {
      image: Ava,
      text: "Integrate.",
      text2: "Automate. Relax!",
      onClick: () => window.open("https://ava.thatapp.io/login", "_blank"),
      resize: false,
    },
  ];

  let name = "";
  if (user?.team_member) {
    name = user?.team_member?.name;
  } else {
    name = user?.name;
  }

  return (
    <div className="choose__service">
      <div className="container">
        <div className="header">
          <div>
            <img src={Logo} alt="logo" />
          </div>

          <div className="logout">
            <span
              style={{ color: "#F7C948", cursor: "pointer" }}
              onClick={() => {
                dispatch(logout());
                history.push("/login");
              }}
            >
              LOGOUT
            </span>
          </div>
        </div>
        <div className="welcome__section">
          <h1 className="title">Welcome {name}!</h1>
          <p className="description mb-0 mt-2">
            Choose a service to immediately begin with ThatApp
          </p>
        </div>
        <div className="connectors mt-0">
          <div className="wrapper mt-0">
            {cards.map((item, index) => (
              <ConnectCard
                key={index}
                image={item.image}
                text={item.text}
                text2={item.text2}
                onClick={item.onClick}
                resize={item.resize}
              />
            ))}
            <RenderByPermission>
              <ConnectCard
                image={Print}
                text="Dynamic Printing Templates,"
                text2="Blockchain security and Smart Contracts!"
                onClick={() => (window.location.href = printLink)}
                resize={false}
              />
            </RenderByPermission>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConnectCard = (props) => {
  const { image, text, text2, onClick, resize } = props;

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
      <div className="connector__box">
        <div className="image__container">
          {resize ? (
            <img src={image} className={"image_props"} alt="podio" />
          ) : (
            <img src={image} alt="podio" />
          )}
        </div>
        <div className="text-center mt-3">
          <p>
            {text} <br /> {text2}
          </p>
        </div>
        <div className="get_started_btn">
          <CustomButton
            tooltip="true"
            tooltip_title="Get Started"
            tooltip_placement="bottom"
            onClick={onClick}
          >
            Get Started
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ChooseService;
