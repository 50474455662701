import React from 'react'
// components
import Navigation from '../components/Navigation'
import GetStarted from '../components/GetStarted'
import Footer from '../components/Footer'
// widgets
import Button from '../widgets/buttons/Button'
import Features from '../widgets/Features'
// style
import './styles/features.scss'
// images
import features_landing from '../images/ava-features2.png'
import features1 from '../images/ava-landing.png'
import features2 from '../images/ava-features1.png'
// icons
import hybrid from '../icons/hybrid.svg'
import data from '../icons/data.svg'
import dataAnalytics from '../icons/dataAnalytics.svg'
import mobile from '../icons/mobile.svg'
import iot from '../icons/iot.svg'
import button_arrow from '../icons/arrow-bottom3.svg'
import ava from '../icons/ava.svg'

const AvaFeatures = () => {
    return <div className="features">
        <Navigation btnBg="bg-bd4-4" color="color-7" />
        <section className="landing bg-9 double-container">
            <div className="text">
                <img src={ava} alt="ava" className="logo" />
                <div className="font-48 color-7">Automate your data from multiple platforms easily</div>
                <div className="color-black-2">Easily migrate, automate and move your data seamlessly from multiple platforms such as Salesforce, Podio, Shopify, etc or even Mysql to any platform of your choice.</div>
                <div className="double-container">
                    <div>
                        <Button 
                            text="Get Started"
                            styling="double-elem bg-bd4-4"
                            id="intercom_link"
                        />
                    </div>
                    <div>
                        <Features 
                            text="See all Features"
                            img={button_arrow}
                            styling="double-elem bg-bd0-none color-7 button-3"
                        />
                    </div>
                </div>
            </div>
            <div className="image"><img src={features_landing} alt="features" /></div>
        </section>
        <section className="automate">
            <div className="head">
                <div className="font-32 bolder">Features to automate your workflow seamlessly</div>
                <div className="color-black-2">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className="double-container">
                <div className="text">
                    <div className="font-24 bolder">Cloud Integration and Flow Management</div>
                    <div className="sub-text">Connect disparate SaaS applications to share & sync data between them automatically and in a fraction of time</div>
                </div>
                <div><img src={features1} alt="features one" /></div>
            </div>
            <div className="double-container">
                <div className="text">
                    <div className="font-24 bolder">API Integration</div>
                    <div className="sub-text">Build new products and services by easily creating all kinds of API mashups.</div>
                </div>
                <div><img src={features2} alt="features two" /></div>
            </div>
        </section>
        <section className="work-easily bg-9">
            <div className="head">
                <div className="font-32 bolder">Features to automate your workflow seamlessly</div>
                <div className="color-black-2">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className="triple-container">
                <div className="item">
                    <img src={hybrid} alt="hybrid" />
                    <div className="font-18 bold">Hybrid Integration</div>
                    <div>Even when you need to connect to the cloud, make sure that the actual data never leaves on-premises</div>
                </div>
                <div className="item">
                    <img src={data} alt="data" />
                    <div className="font-18 bold">Data Migration</div>
                    <div>Relocate all data between systems, databases and applications without heavy custom coding and at low cost</div>
                </div>
                <div className="item">
                    <img src={dataAnalytics} alt="data analytics" />
                    <div className="font-18 bold">Data Analytics</div>
                    <div>Bring together business analytics and corporate applications to eliminate data silos and get maximum value from your analytic investments</div>
                </div>
                <div className="item">
                    <img src={mobile} alt="mobile" />
                    <div className="font-18 bold">Mobile Integration</div>
                    <div>Empower the creation of digital services by connecting mobile apps with any kind of back-end business applications faster and with less hustle</div>
                </div>
                <div className="item">
                    <img src={iot} alt="iot" />
                    <div className="font-18 bold">IoT Integration</div>
                    <div>Connect your IoT-platform with other cloud and on-premises applications or collect data from multiple IoT-platforms</div>
                </div>
            </div>
        </section>
        {/* <section className="testimonial">
            <div className="inner">
                <img src={quote} alt="quote" />
                <div className="font-18">We have been using Sync for more than 2 years and now I can say for sure that we need it and only Sync, nothing else. I do not want another window on my computer, I want it to be linked with my Data and it is perfect and simple.</div>
                <div className="person">
                    <div className="image"></div>
                    <div className="info">
                        <div className="bold">Arnold Foster</div>
                        <div className="font-12">Head of Operations, TalentPool</div>
                    </div>
                </div>
            </div>
        </section> */}
        <GetStarted btnBg="bg-bd4-4" bg="bg-9" color="color-7" id="intercom_link" />
        <Footer bg="bg-9" color="color-7" />
    </div>
}

export default AvaFeatures
