import React from "react";

import "./ConfirmModal.css";

const ActionModal = ({ message, toggleActionModal, performAction }) => {
    return (
        <div className="confirm__modal">
            <div className="modal__content">
                <div>
                    <div className="modal__card">
                        <div>
                            <h6 className="mt-3">{message}</h6>
                            <button
                                onClick={() => performAction()}
                            >
                                Confirm
                            </button>
                            <p className="cancel" onClick={() => toggleActionModal(false, "", null, null)} >
                                Cancel
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionModal;