import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import carretDown from "../../../assets/icons/carretDown.svg";
import { useEffect } from "react";
import { formatNumber } from "utils/formatNumber";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "16px",
  },
  spaceItem: {
    height: "64px",
    width: "100%",
    backgroundColor: "#F7F7F7",
    padding: "23px 32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "40px",
    color: "#222",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  itemDiv: {
    display: "flex",
    alignItems: "center",
    gap: "40px",
  },
  dropdown: {
    display: "flex",
    cursor: "pointer",
  },
  forDropdown: {
    fontSize: "14px",
    marginRight: "15px",
  },
  checkInput: {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    appearance: "auto",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  paddingLeft: {
    paddingLeft: "32px",
  },
  paddingRight: {
    paddingRight: "32px",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
  },
  rotate180: {
    transform: "rotate(180deg)",
  },
  center: {
    textAlign: 'center'
  },
  [theme.breakpoints.down("sm")]: {
    checkInput: {
      marginBottom: "0px",
    },
  },
}));

const SpaceDropdownV2 = ({ item, handleAppCheckBox, handleCheckBox, selectedAppsIds, selectedSpaceIds }) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const classes = useStyles();

  const handleCheckAll = () => {
    const newApps = item.apps?.map((el) => el.app_id);
    
    const collectivePrice = item.apps?.reduce((accumulator, el) => {
      return accumulator + el.items_cost + el.files_cost
    }, 0);

    const includedPrice = item.apps
                      ?.filter(el => selectedAppsIds.includes(el.app_id))
                      .reduce((accumulator, el) => accumulator + el.items_cost + el.files_cost, 0);

    const collectiveItemsCount = item.apps?.reduce((accumulator, el) => {
      return accumulator + el.items_count
    }, 0);

    const includedItemsCount = item.apps
                      ?.filter(el => selectedAppsIds.includes(el.app_id))
                      .reduce((accumulator, el) => accumulator + el.items_count, 0);

    const collectiveFilesCount = item.apps?.reduce((accumulator, el) => {
      return accumulator + el.item_files_count
    }, 0);

    const includedFilesCount = item.apps
                      ?.filter(el => selectedAppsIds.includes(el.app_id))
                      .reduce((accumulator, el) => accumulator + el.item_files_count, 0);

    setSelectedApps((prev) => {
      return isAllSelected || prev.includes(newApps) ? [] : newApps;
    });
    setIsAllSelected((prev) => !prev);
    handleCheckBox(
      item.space_id, 
      newApps, 
      collectivePrice, 
      includedPrice, 
      collectiveItemsCount,
      includedItemsCount,
      collectiveFilesCount,
      includedFilesCount
    )
  }

  const handleCheckboxChange = (app) => {
    const cost = app.files_cost + app.items_cost;
    const items_count = app.items_count;
    const files_count = app.item_files_count;

    setSelectedApps((prev) => {
      let newArray = [];
      if (prev.includes(app.app_id)) {
        newArray = prev.filter((ele) => ele !== app.app_id);
      } else {
        newArray = [...prev, app.app_id];
      }

      handleAppCheckBox(app.app_id, newArray.length, item.space_id, cost, items_count, files_count)
      return newArray
    });
  };

  useEffect(() => {
    const isSelected = selectedSpaceIds.includes(item.space_id)
    const allApps = item.apps
                        ?.filter(el => selectedAppsIds.includes(el.app_id))
                        ?.map((el) => el.app_id);

    if (isSelected) {
      setSelectedApps(allApps)
    } else {
      setSelectedApps([])
    }
    setIsAllSelected(isSelected);
  }, [selectedSpaceIds, item, selectedAppsIds]);

  useEffect(() => {
    if (item.apps.length > 0) {
      setIsAllSelected(selectedApps.length > 0);
    }
  }, [selectedApps, item]);
  
  return (
    <div className={classes.container}>
      <div className={classes.spaceItem}>
        <div className={classes.itemDiv}>
          <input
            type="checkbox"
            className={classes.checkInput}
            onChange={handleCheckAll}
            id={item.space_id}
            name={item.space_id}
            checked={isAllSelected || selectedSpaceIds.includes(item.space_id)}
            value={item.space_id}
          />
          {item.name} - {item.space_id}
        </div>
        <div
          className={classes.dropdown}
          onClick={() => setShowItems((prev) => !prev)}
        >
          <img src={carretDown} alt="caretDownIcon" />
        </div>
      </div>

      {showItems && (
        <div className="table-responsive">
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.th}></th>
                <th className={classes.th}>APP ID</th>
                <th className={classes.th}>APP NAME</th>
                <th className={classes.th}>ITEM COUNT</th>
                <th className={classes.th}>FILE COUNT</th>
                <th className={classes.th}>COST</th>
              </tr>
            </thead>
            <tbody>
              {item.apps.length > 0 ? (
                item.apps?.map((app) => (
                  <tr key={app.app_id} className={classes.tableBodyRow}>
                    <td className={`${classes.td} ${classes.paddingLeft}`}>
                      <input
                        type="checkbox"
                        className={classes.checkInput}
                        id={app.app_id}
                        name={app.app_id}
                        checked={selectedAppsIds.includes(app.app_id)}
                        onChange={(e) =>
                          handleCheckboxChange(app)
                          // handleCheckboxChange(parseInt(e.target.value), app.files_cost + app.items_cost)
                        }
                        value={app.app_id}
                      />
                    </td>
                    <td className={classes.td}>{app.app_id}</td>
                    <td className={classes.td}>{app.name}</td>
                    <td className={classes.td}>{app.items_count}</td>
                    <td className={classes.td}>{app.item_files_count}</td>
                    <td className={classes.td}>${formatNumber(app.files_cost + app.items_cost)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className={`${classes.center} ${classes.td}`}>
                    No items found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SpaceDropdownV2;