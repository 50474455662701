import React, { useState } from "react";
import Swal from "sweetalert2";
import "./SyncTable.css";
import SettingsIcon from "../../../assets/icons/edit-icon.svg";
import SyncIcon from "../../../assets/icons/sync-icon.svg";
import EyeIcon from "../../../assets/icons/eye-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../../assets/icons/trash-icon.svg";
import downIcon from "../../../assets/icons/actionDown.svg";
import { useHistory } from "react-router-dom";
import RenderByPermission from "components/RenderByPermission";
import SelectSyncTypeModal from "./SelectSyncTypeModal";
import lock from "../../../assets/icons/lockIcon.svg";
import errorIcon from "../../../assets/icons/errorIcon.svg";
import successIcon from "../../../assets/icons/successIcon.svg";
import unlock from "../../../assets/icons/unlockIcon.svg";
import FileInfoPop from "./FileInfoPop";
import WorkflowPop from "./WorkflowPop";

const SyncTable = ({ user, orgs, toggleModal, gdprModal, orgUpdateModal }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(null);
  const [openWorkPopOver, setOpenWorkPopOver] = useState(null);

  const handlePopverClick = (org) => {
    setOpenPopOver(org);
    setOpenWorkPopOver(null);
  };

  const handleWorkPopverClick = (org) => {
    setOpenWorkPopOver(org);
    setOpenPopOver(null);
  };
  const history = useHistory();
  const [selectedOrg, setSelectedOrg] = useState({ orgId: "", orgName: "" });
  const handleInfoClick = (orgId, orgName) => {
    setSelectedOrg({ orgId, orgName });
    setOpenAlert(true);
  };

  const handleAuditClick = (selectedOrgId, selectedOrgName) => {
    history.push("/audit", { selectedOrgId, selectedOrgName });
  };

  const handleFilePopClose = () => {
    setOpenPopOver(null);
  };

  const handleWorkPopClose = () => {
    setOpenWorkPopOver(null);
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else {
      return num.toLocaleString();
    }
  }

  // <div className="value">
  //   {dashboardCounts?.items ? formatNumber(dashboardCounts.items) : ''}
  // </div>

  return (
    <div className="sync__table">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ backgroundColor: "#FBFBFB" }}>
              {/* <th style={{ borderBottom: "none" }}></th> */}
              <th style={{ borderBottom: "none" }}>Name</th>
              <th style={{ borderBottom: "none" }}>Type</th>
              <th style={{ borderBottom: "none" }}>Details</th>
              <th style={{ borderBottom: "none" }}>Files</th>
              <th style={{ borderBottom: "none" }}>Workflows</th>
              <th className="created_on" style={{ borderBottom: "none" }}>
                Created On
              </th>
              <th style={{ borderBottom: "none" }}>Status</th>
              <th style={{ borderBottom: "none" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgs
              ? orgs.map((org, i) => {
                  let count =
                    org.details && org.details.mongo_summary
                      ? org.details.mongo_summary.count
                      : false;
                  let details = org.details;

                  return (
                    <tr key={i}>
                      <td style={{ verticalAlign: "middle" }}>
                        <p className="title m-0">{org.name}</p>
                        <small
                          style={{ color: "#DE911D", fontWeight: "500" }}
                          className="num"
                        >
                          {org.org_id}
                        </small>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{org.type}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        <p className="mb-1">
                          <span className="font-weight-bold">
                            {count ? Number(count.spaces).toLocaleString() : 0}{" "}
                          </span>{" "}
                          Spaces
                        </p>
                        <p className="mb-1">
                          <span className="font-weight-bold">
                            {count ? Number(count.apps).toLocaleString() : 0}{" "}
                          </span>{" "}
                          Apps
                        </p>
                        <p className="mb-1">
                          <span className="font-weight-bold">
                            {count ? formatNumber(Number(count.items)) : 0}
                          </span>{" "}
                          Items{" "}
                        </p>
                      </td>
                      <td
                        onMouseLeave={() => handleFilePopClose()}
                        onMouseEnter={() => handlePopverClick(org)}
                        style={{ verticalAlign: "middle" }}
                      >
                        <span
                          style={{ verticalAlign: "middle", marginRight: "5%" }}
                        >
                          {org.details.file_haven === 1 ? (
                            <span
                              style={{ verticalAlign: "middle", width: "3%" }}
                            >
                              <img src={lock} alt="" />
                            </span>
                          ) : (
                            <span
                              // onMouseEnter={() => handlePopverClick(org)}
                              style={{ verticalAlign: "middle", width: "3%" }}
                            >
                              <img src={unlock} alt="" />
                            </span>
                          )}
                          {openPopOver === org && (
                            <FileInfoPop
                              org={org.name}
                              orgId={org.org_id}
                              isProtected={org.details.file_haven}
                              handleFilePopClose={handleFilePopClose}
                              count={count}
                              fileCount={count.files}
                            />
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {count ? formatNumber(Number(count.files)) : 0}
                        </span>{" "}
                        Files
                      </td>

                      <td
                        onMouseLeave={() => handleWorkPopClose()}
                        onMouseEnter={() => handleWorkPopverClick(org)}
                        style={{ verticalAlign: "middle" }}
                      >
                        {details?.mongo_summary?.podio_workflow_expired ===
                        false ? (
                          <span style={{ width: "3%" }}>
                            <img src={errorIcon} alt="" />
                          </span>
                        ) : (
                          <span
                            onMouseEnter={() => handleWorkPopverClick(org)}
                            style={{ width: "3%" }}
                          >
                            <img src={successIcon} alt="" />
                          </span>
                        )}
                        <span
                          style={{ verticalAlign: "middle", marginRight: "5%" }}
                        >
                          {openWorkPopOver === org && (
                            <WorkflowPop
                              org={org.name}
                              orgId={org.org_id}
                              isProtected={
                                details?.mongo_summary?.podio_workflow_expired
                              }
                              handleWorkPopClose={handleWorkPopClose}
                              count={count.total_workflows}
                              fileCount={count.files}
                            />
                          )}
                        </span>
                        <span className="font-weight-bold mt-2">
                          {count
                            ? formatNumber(Number(count.total_workflows))
                            : 0}
                        </span>{" "}
                        {formatNumber(Number(count.total_workflows)) === 0
                          ? "Workflow"
                          : "Workflows"}
                      </td>

                      <td style={{ verticalAlign: "middle" }}>
                        {details && details.mongo_summary
                          ? details.mongo_summary.last_synced
                          : "Queued"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <span className="info">
                          {details && details.mongo_summary
                            ? details.status
                            : "Queued"}
                        </span>
                      </td>

                      <td style={{ verticalAlign: "middle" }}>
                        <div className="dropdown mt-2 action-btn-mobile">
                          <span
                            style={{ color: "#DE911D" }}
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Actions
                            <img alt="drop" src={downIcon} />
                          </span>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {details.status === "Done" ? (
                              <span
                                className="dropdown-item"
                                // onClick={() =>
                                //   toggleModal(
                                //     true,
                                //     "You are about to resync your organization",
                                //     org.org_id,
                                //     org.name
                                //   )
                                // }

                                onClick={() =>
                                  handleInfoClick(org.org_id, org.name)
                                }
                              >
                                <img
                                  style={{ marginRight: "5px" }}
                                  src={SyncIcon}
                                  alt="icon"
                                />{" "}
                                Resync
                              </span>
                            ) : (
                              <span className="dropdown-item  disabled">
                                <img src={SyncIcon} alt="icon" /> Resync
                              </span>
                            )}
                            <RenderByPermission allowTeamMemberAccess={true}>
                              <span
                                className="dropdown-item"
                                onClick={() =>
                                  orgUpdateModal(org.org_id, org.name, true)
                                }
                              >
                                <img src={EditIcon} alt="icon" width="12px" />
                                Edit
                              </span>
                            </RenderByPermission>
                            {details && (
                              <>
                                {details.status === "Done" ? (
                                  <span
                                    style={{ padding: "0" }}
                                    onClick={() =>
                                      handleAuditClick(org.org_id, org.name)
                                    }
                                  >
                                    <span className="dropdown-item">
                                      {" "}
                                      <img
                                        src={EyeIcon}
                                        alt="icon"
                                        width="13px"
                                      />
                                      Audit
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    className="dropdown-item"
                                    onClick={() => {
                                      Swal.fire(
                                        "Oops..",
                                        "Organization is currently syncing, you can not perform this operation",
                                        "error"
                                      );
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src={EyeIcon}
                                      alt="icon"
                                      width="13px"
                                    />
                                    Audit
                                  </span>
                                )}

                                <RenderByPermission>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => {
                                      user?.type ||
                                      user?.permissions?.tools?.gdpr_settings
                                        ? gdprModal(org, true)
                                        : history.push(
                                            `/dashboard/account?section=plans&message=unsupported-plan`
                                          );
                                    }}
                                  >
                                    <img
                                      src={SettingsIcon}
                                      alt="icon"
                                      width="13px"
                                    />
                                    GDPR Settings
                                  </span>
                                </RenderByPermission>
                              </>
                            )}
                            <RenderByPermission allowTeamMemberAccess={false}>
                              <span
                                className="dropdown-item"
                                style={{ color: "#E12D39" }}
                                onClick={() =>
                                  toggleModal(
                                    true,
                                    "You are about to delete your organization",
                                    org.org_id,
                                    org.name
                                  )
                                }
                              >
                                <img src={DeleteIcon} alt="icon" width="13px" />{" "}
                                Delete
                              </span>
                            </RenderByPermission>
                          </div>
                        </div>
                      </td>
                      <SelectSyncTypeModal
                        openModal={openAlert}
                        orgId={selectedOrg.orgId}
                        orgName={selectedOrg.orgName}
                        handleClose={() => {
                          setOpenAlert(false);
                        }}
                      />
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SyncTable;
