import React, { useState } from "react";
import "./faq.css";
import plus from "../../Images/plus.png";
import minus from "../../Images/minus.png";

export default function FAQ() {
  const faqs = [
    {
      question: "What is Print?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Databases are foundational in bolstering your business, enabling you to not only retain",
    },
    {
      question: "What does Sync do?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Databases are foundational in bolstering your business, enabling you to not only retain",
    },
    {
      question: "What type of documents are supported on print?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Databases are foundational in bolstering your business, enabling you to not only retain",
    },
    {
      question: "What is AVA?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Databases are foundational in bolstering your business, enabling you to not only retain",
    },
    {
      question: "Is this free?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Databases are foundational in bolstering your business, enabling you to not only retain",
    },
  ];

  const [isOpen, setIsOpen] = useState([]);

  function handleClick(index) {
    if (isOpen.includes(index)) {
      setIsOpen((opens) => opens.filter((num) => num !== index));
    } else {
      setIsOpen((opened) => [...opened, index]);
    }
  }

  return (
    <div className="faq-cont">
      <div className="faq-text-cont">
        <h2>Frequently Asked Questions</h2>
        <p>
          If the question is not available on our FAQ section, Feel free to
          contact us personally, we will resolve your respective doubts.
        </p>
      </div>
      <div className="faq-questions-cont">
        {faqs.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleClick(index)}>
              <h2>{item.question}</h2>
              <img
                src={isOpen.includes(index) ? minus : plus}
                alt="open or close"
              />
            </div>
            <div
              className={`faq-answer ${
                isOpen.includes(index) ? "faq-open" : "faq-close"
              }`}
            >
              <div className="overflow-hidden">
                <hr />
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
