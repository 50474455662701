import React from 'react'
import {useHistory} from 'react-router-dom'
// components
import Navigation from '../components/Navigation'
import GetStarted from '../components/GetStarted'
import Footer from '../components/Footer'
// widgets
import Button from '../widgets/buttons/Button'
import Features from '../widgets/Features'
// style
import './styles/features.scss'
// images
import features_landing from '../images/print-landing.png'
import features1 from '../images/print-features1.png'
import features2 from '../images/print-features2.png'
// icons
import button_arrow from '../icons/arrow-bottom2.svg'
import print from '../icons/print.svg'

const PrintFeatures = () => {
    const history = useHistory()
    return <div className="features">
        <Navigation btnBg="bg-bd3-3" color="color-6" />
        <section className="landing bg-8 double-container">
            <div className="text">
                <img src={print} alt="print" className="logo" />
                <div className="font-48 color-6">Easily Generate Documents from your Data</div>
                <div className="color-black-2">Easily generate a document(pdf, Docx, doc) from any Datasource such as Podio. Creating of documents has been made easy with our tool.</div>
                <div className="double-container">
                    <div>
                        <Button 
                            text="Get Started"
                            styling="double-elem bg-bd3-3"
                            function={() => history.push('/register')}
                        />
                    </div>
                    <div>
                        <Features 
                            text="See all Features"
                            img={button_arrow}
                            styling="double-elem bg-bd0-none color-6 button-3"
                        />
                    </div>
                </div>
            </div>
            <div className="image"><img src={features_landing} alt="features" /></div>
        </section>
        <section className="automate">
            <div className="head">
                <div className="font-32 bolder">Features to automate your workflow seamlessly</div>
                <div className="color-black-2">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className="double-container">
                <div className="text">
                    <div className="font-24 bolder">Template Creation</div>
                    <div className="sub-text">You can create the way you want your document to look using our drag and drop tool, this would give you the ability to print the document the way you want</div>
                </div>
                <div><img src={features1} alt="features one" /></div>
            </div>
            <div className="double-container">
                <div className="text">
                    <div className="font-24 bolder">Platform Integration</div>
                    <div className="sub-text">With a click of a button, you can easily get your document within your platform. Current support is only available in Podio through hooks.</div>
                </div>
                <div><img src={features2} alt="features two" /></div>
            </div>
        </section>
        {/* <section className="testimonial">
            <div className="inner">
                <img src={quote} alt="quote" />
                <div className="font-18">We have been using Sync for more than 2 years and now I can say for sure that we need it and only Sync, nothing else. I do not want another window on my computer, I want it to be linked with my Data and it is perfect and simple.</div>
                <div className="person">
                    <div className="image"></div>
                    <div className="info">
                        <div className="bold">Arnold Foster</div>
                        <div className="font-12">Head of Operations, TalentPool</div>
                    </div>
                </div>
            </div>
        </section> */}
        <GetStarted btnBg="bg-bd3-3" bg="bg-8" color="color-6" function={() => history.push('/register')} />
        <Footer bg="bg-8" color="color-6" />
    </div>
}

export default PrintFeatures
