import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
// components
import Navigation from '../components/Navigation'
import GetStarted from '../components/GetStarted'
import Footer from '../components/Footer'
// widgets
import Text from '../widgets/inputs/Text'
import Button from '../widgets/buttons/Button'
// style
import './styles/home.scss'
import './styles/general.scss'
// illustrations
import computer from '../illustrations/computer.svg'
import features from '../illustrations/features.svg'
import email from '../illustrations/email.svg'
// icons
import check from '../icons/check-mark.svg'

import adobe from '../icons/adobe.svg'
import citrix from '../icons/citrix.svg'
import ea from '../icons/ea.svg'
import marylandUniversity from '../icons/maryland-university.svg'
import fox from '../icons/fox.svg'

const Home = () => {
    const history = useHistory();
    const { search } = useLocation();
    const partnerId = new URLSearchParams(search).get("partner");
    if (partnerId) {
        localStorage.setItem("partnerId", partnerId)
    }
    const [data, setData] = useState({email:""});

    const handleChange = (e) => {
      const { name, value } = e.target;
  
      setData({ ...data, [name]: value });
    };


    return <div className="home">
        <Navigation btnBg="bg-bd1-1" color="color-1" />
        <section className="landing bg-2 double-container">
            <div>
                <div className="font-48 color-1">Automate, Backup & Synchronize your Data Seamlessly</div>
                <div className="color-black-2">Gain a refreshing perspective of your business processes and strategic initiatives through the eyes of our skilled workflow architects and system integrators. </div>
                <form>
                    <div>
                        <Text 
                            type="email"
                            placeholder="Email Address"
                            styling="double-elem"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Button 
                            text="Get Started"
                            styling="full-input bg-bd1-1"
                            function={() => history.push(`/register?email=${data.email}`)}
                        />
                    </div>
                </form>
            </div>
            <div className="mobile-alt"><img src={computer} alt="computer" /></div>
        </section>
        <section className="join-companies">
            <div className="inner">
                <div className="font-18">Join leading companies and startups in backing up and syncing their data with Sync</div>
                <div className="icons">
                    <img src={adobe} alt="adobe" />
                    <img src={citrix} alt="citrix" />
                    <img src={ea} alt="ea" />
                    <img src={marylandUniversity} alt="marylandUniversity" />
                    <img src={fox} alt="fox" />
                </div>
            </div>
        </section>
        <section className="cognitive-features double-container">
            <div>
                <div className="head">
                    <div className="font-38 bolder">Gain an unfair advantage with cognitive features</div>
                    <div className="color-black-2">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
                </div>
                <div className="list double-container">
                    <div className="item">
                        {/* AVA */}
                        <div className="font-18 color-1 bolder">
                            Integrate. Automate. Relax.
                        </div>
                        <div>AVA is our Artificial Intelligence and your Automated Virtual Assistant. As a Hybrid Integration Platform for your ERP, SaaS, IoT...</div>
                        {/* <div className="action color-1">Learn More &gt;</div> */}
                    </div>
                    <div className="item">
                        {/* SYNC */}
                        <div className="font-18 color-1 bolder">
                            Backup and Protect your Sensitive Data
                        </div>
                        <div>Sync enables you to backup your entire organization's data, ensuring protection moving forward...</div>
                        {/* <div className="action color-1">Learn More &gt;</div> */}
                    </div>
                    <div className="item">
                        {/* PRINT */}
                        <div className="font-18 color-1 bolder">
                            Dynamic Printing Templates, Blockchain security and Smart Contracts
                        </div>
                        <div>Your time is valuable. Don’t waste it by creating manual documents, Print is...</div>
                        {/* <div className="action color-1">Learn More &gt;</div> */}
                    </div>
                    <div className="item">
                        {/* AUDIT */}
                        <div className="font-18 color-1 bolder">
                            User Management made Simple
                        </div>
                        <div>Managing access rights and the roles/responsibilities for all of your employees in any system can be difficult. We've launched Audit to help with just that!</div>
                        {/* <div className="action color-1">Learn More &gt;</div> */}
                    </div>
                </div>
            </div>
            <div className="image"><img src={features} alt="cognitive features" /></div>
        </section>
        <section className="innovative-approach bg-2">
            <div className="double-container">
                <div>
                    <div className="font-30 color-1">An innovative approach to backing up and synchronizing your data</div>
                    <div>We agonize over the right distractions so your teams don't need to worry about data loss or connections to other systems</div>
                </div>
                <div className="list">
                    <div className="item">
                        <div><img src={check} alt="check" /></div> 
                        <div>Gain insight into your data and understand how they are interconnected</div>
                    </div>
                    <div className="item">
                        <div><img src={check} alt="check" /></div> 
                        <div>We have integrations and connections to a lot of systems thereby making data migration seamless and rediculously easy </div>
                    </div>
                    <div className="item">
                        <div><img src={check} alt="check" /></div> 
                        <div>Our systems operate with 99.9%+ uptime and are highly scalable and redundant</div>
                    </div>
                    <div className="item">
                        <div><img src={check} alt="check" /></div> 
                        <div>Automate every aspect of your business and cut out time-intensive, repetitive tasks</div>
                    </div>
                </div>
            </div>
            <div className="email-image"><img src={email} alt="email" /></div>
        </section>
        {/* <section className="testimonial">
            <div className="head">
                <div className="font-32 bolder">Here’s what people are saying about sync</div>
                <div className="color-black-2">From Ceo’s to Head of operations people love using Sync</div>
            </div>
            <div className="triple-container">
                <div className="item">
                    <img src={quote} alt="open quote" />
                    <div className="text">The secret behind a successful team is often what happens behind the scenes - trackable communication, streamlined processes and effortless collaboration. With the help of TECHeGO, which paired their expertise and best practices with our unique needs, Agency Ingram Micro now has a customized workflow tool.</div>
                    <div className="person">
                        <div className="image"
                            style={{
                                backgroundImage: `url(${jennifer})`,
                                backgroundSize:`cover`,
                                backgroundPosition:`center`
                            }}
                        ></div>
                        <div className="info">
                            <div className="bold">Jennifer Anaya, EVP</div>
                            <div className="font-12">Agency Ingram Micro</div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={quote} alt="open quote" />
                    <div className="text">TECHeGO has become an extension to our own business. With their partnership, we've been able to not only create a comprehensive project management suite, but we've also been able to better monitor the delivery and productivity of our business through data retrieval and ticket sales.</div>
                    <div className="person">
                        <div className="image"
                            style={{
                                backgroundImage: `url(${jason})`,
                                backgroundSize:`cover`,
                                backgroundPosition:`center`
                            }}
                        ></div>
                        <div className="info">
                            <div className="bold">Jason Isaac, Lead Designer</div>
                            <div className="font-12">Cirque Du Soleil</div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={quote} alt="open quote" />
                    <div className="text">First and foremost, customer service is awesome. The company culture is much more appreciative of a small business and will treat you with respect and dignity, I'm literally blown away. This is the answer we've all been looking for.</div>
                    <div className="person">
                        <div className="image"
                            style={{
                                backgroundImage: `url(${william})`,
                                backgroundSize:`cover`,
                                backgroundPosition:`center`
                            }}
                        ></div>
                        <div className="info">
                            <div className="bold">William Nwogbo, CEO</div>
                            <div className="font-12">Head of Operations, TalentPool</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         */}
        <GetStarted btnBg="bg-bd1-1" bg="bg-3" color="color-1" function={() => history.push('/register')} />
        <Footer bg="bg-3" color="color-1" />
    </div>
}

export default Home
