import React from 'react'
import history from "../../../helpers/history";
// components
import Popover from '@material-ui/core/Popover';
// icons
import printSmall from "../../../assets/images/printsmall.png";
import avaSmall from "../../../assets/images/avasmall.png";
import syncSmall from "../../../assets/images/syncsmall.png";
// styles
import './buttons/style.scss'
import './general.scss'

const Button = e => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const scrollTop = () => {
        window.scrollTo(0, 0)
    }
    
    return <>
        <button type={e.type} className={`${e.styling} button-image-container`} onClick={handleClick}>
            <div className="button-container">{e.text}</div>
            <div className="button-container"><img src={e.img} alt="img" /></div>
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <div className="dropdown-container font-20 bold">
                <div className="dropdown-item">
                    <img 
                        src={syncSmall} 
                        alt="sync" 
                        onClick={() => {history.push("/features/sync") 
                        scrollTop()}} 
                    />
                </div>
                <div className="dropdown-item">
                    <img 
                        src={avaSmall} 
                        alt="ava" 
                        onClick={() => {history.push("/features/ava") 
                        scrollTop()}} 
                    />
                </div>
                <div className="dropdown-item">
                    <img 
                        src={printSmall} 
                        alt="print" 
                        onClick={() => {history.push("/features/print") 
                        scrollTop()}} 
                    />
                </div>
            </div>
        </Popover>
    </>
}

export default Button
