import React, { useState } from "react";
import avatars from "../../Images/avatar-collage.png";
import "./feature.css";
import Sync from "../integration-features/Sync";
import { AnimatePresence } from "framer-motion";
import Ava from "../integration-features/Ava";
import Print from "../integration-features/Print";

export default function Features() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div className="">
      <div className="integration-workflow-title">
        <h2>Features to automate your workflow seamlessly</h2>
        <div className="integration-avatar-cont">
          <p>Used by</p>
          <img src={avatars} alt="users" />
          <p>
            <span>10k+</span> users
          </p>
        </div>
        <p className="integration-avatar-sub">
          Used by <span>10k+</span>users
        </p>
      </div>
      <div className="tab-cont">
        <div className="tab-group">
          <button onClick={() => handleTabClick(0)}>Sync</button>
          <button onClick={() => handleTabClick(1)}>Ava</button>
          <button onClick={() => handleTabClick(2)}>Print</button>
          <div
            style={{
              width: "33.333%",
              height: "calc(100% - 1rem)",
              left: `calc(${activeTab * 33.333}%)`,
            }}
            className="active-tab"
          ></div>
        </div>
      </div>
      <div>
        <AnimatePresence mode="wait">
          {activeTab === 0 ? <Sync /> : activeTab === 1 ? <Ava /> : <Print />}
        </AnimatePresence>
      </div>
    </div>
  );
}
