import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import SpaceDropdownV2 from "./SpaceDropdownV2";
import CheckoutCardV2 from "./CheckoutCardV2";

const useStyles = makeStyles((theme) => ({
  spaceItem: {
    height: "64px",
    width: "100%",
    backgroundColor: "#F7F7F7",
    marginBottom: "16px",
    padding: "23px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    color: "#222",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  container: {
    display: "flex",
    gap: "73px",
  },
  cartItems: {
    width: "734px",
    paddingTop: "10px",
  }
}));

const OrgCheckoutV2 = ({
  setActiveScreen,
  setData,
  data,
  appData,
  sourceData,
  submitData,
  loading,
  isStructure
}) => {
  const classes = useStyles();
  const [selectedSpaceIds, setSelectedSpaceIds] = useState([]);
  const [selectedAppsIds, setSelectedAppsIds] = useState([]);
  const [responseData, setResponseData] = useState({
    spaces_count: 0,
    apps_count: 0,
    unit_item_cost: 0.05,
    subtotal: 0,
    taxes_cost: 0,
    files_count: 0,
    items_count: 0
  });

  useEffect(() => {
    cache() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cache = () => {
    if (data.length > 0) {
      const storedData = localStorage.getItem('responseData');
      if (storedData) {
        setResponseData(JSON.parse(storedData));
      }
    } else {
      localStorage.removeItem('responseData');
    }
  }

  useEffect(() => {
    localStorage.setItem('responseData', JSON.stringify(responseData));
  }, [responseData]);

  const handleCheckBox = (
    value, 
    apps, 
    cost, 
    removableCost,       
    items_count,
    removable_items_count,
    files_count,
    removable_files_count 
  ) => {
    setSelectedSpaceIds((prev) => {
      if (prev.includes(value)) {
        setResponseData(prev => ({
          ...prev,
          subtotal: isStructure ? prev.subtotal : prev.subtotal - removableCost,
          items_count: prev.items_count - removable_items_count,
          files_count: prev.files_count - removable_files_count
        }));
        setSelectedAppsIds((prevApps) =>
          prevApps.filter((appId) => !apps.includes(appId))
        );
        return prev.filter((ele) => ele !== value);
      } else {
        setResponseData(prev => ({
          ...prev,
          subtotal: isStructure ? prev.subtotal : prev.subtotal + cost,
          items_count: prev.items_count + items_count,
          files_count: prev.files_count + files_count
        }));
        setSelectedAppsIds((prev) => [...prev, ...apps]);
        return [...prev, value];
      }
    });
  }

  const handleAppCheckBox = (appId, appIdsLength, space, cost, items_count, files_count) => {
    if (appId && space) {
      setSelectedSpaceIds((prevSpaceIds) => {
        if (appIdsLength > 0) {
          return prevSpaceIds.includes(space) ? prevSpaceIds : [...prevSpaceIds, space];
        } else {
          return prevSpaceIds.filter((spaceId) => spaceId !== space);
        }
      });
    
      setSelectedAppsIds((prevAppIds) => {
        if (prevAppIds.includes(appId)) {
          setResponseData(prev => ({
            ...prev,
            subtotal: isStructure ? prev.subtotal : prev.subtotal - cost,
            items_count: prev.items_count - items_count,
            files_count: prev.files_count - files_count
          }));
          return prevAppIds.filter((ele) => ele !== appId);
        } else {
          setResponseData(prev => ({
            ...prev,
            subtotal: isStructure ? prev.subtotal : prev.subtotal + cost,
            items_count: prev.items_count + items_count,
            files_count: prev.files_count + files_count
          }));
          return [...prevAppIds, appId];
        }
      });
    }
  }

  useEffect(() => {
    if (selectedSpaceIds.length > 0) {
      setData((prev) => ({ ...prev, source_space_ids: selectedSpaceIds }));
    }
    setResponseData(prev => ({
      ...prev,
      spaces_count: selectedSpaceIds.length
    }));
  }, [selectedSpaceIds, setData]);

  useEffect(() => {
    if (selectedAppsIds.length > 0) {
      setData((prev) => ({ ...prev, source_app_ids: selectedAppsIds }));
    }
    setResponseData(prev => ({
      ...prev,
      apps_count: selectedAppsIds.length
    }));
  }, [selectedAppsIds, setData]);

  useEffect(() => {
    setSelectedSpaceIds(data);
  }, [data]);

  useEffect(() => {
    setSelectedAppsIds(appData);
  }, [appData]);

  useEffect(() => {
    if (isStructure) {
      setResponseData(prev => ({
        ...prev,
        subtotal: selectedAppsIds.length * 2
      }));
    }
  }, [selectedAppsIds, isStructure]);

  const handleSubmit = async () => {
    const response = await submitData();
    if (response) {
      setActiveScreen("payment");
    }
  }

  return (
    <div className={classes.container}>
      <div className={`${classes.cartItems} table-responsive`}>
        {sourceData.processing && (
          <div className={classes.spaceItem}>
            <CircularProgress size={24} />
          </div>
        )}
        {!sourceData.processing &&
          sourceData.data &&
          sourceData.data.map((item) => (
            <SpaceDropdownV2
              key={item.space_id}
              {...{ item, handleAppCheckBox, handleCheckBox, selectedAppsIds, selectedSpaceIds, responseData }}
              id={item.space_id}
              name={item.space_id}
              setData={setData}
            />
          ))}
      </div>
      <CheckoutCardV2
        responseData={responseData}
        onClick={handleSubmit}
        loading={loading}
        btnTitle="Proceed to payment"
      />
    </div>
  );
};

export default OrgCheckoutV2;
