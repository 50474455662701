import React, { useState, useEffect, useMemo, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./homepage.css";
import arrRight from "../Images/arrow-right-hero.png";
import card1 from "../Images/hero-card1.png";
import card2 from "../Images/hero-card2.png";
import heroImg from "../Images/hero-img.png";
import adobe from "../Images/adobe.png";
import fox from "../Images/fox.png";
import uoa from "../Images/uoa.png";
import citrix from "../Images/citrix.png";
import ea from "../Images/ea.png";
import Features from "../components/homepage/Features";
import Innovation from "../components/homepage/Innovation";
import Workflow from "../components/homepage/Workflow";
import Reviews from "../components/homepage/Reviews";
import FAQ from "../components/homepage/FAQ";
import "./generalStyle.css";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { useHistory } from "react-router-dom";

export default function Homepage() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const words = useMemo(
    () => [
      "Seamlessly!",
      "Effortlessly!",
      "Automatically!",
      "Continuously!",
      "Instantly!",
    ],
    []
  );

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 4000); // Change word every 4 seconds
    };
    startInterval();
    const clearAndRestartInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      startInterval();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        clearAndRestartInterval();
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [words.length]);

  return (
    <div>
      <Header/>
      <div className="hero-section">
        <div className="hero-section-inner">
          <div className="hero-text">
            <h1>
              <span className="hero-title">Automate,</span>
              <span className="hero-title">Backup</span>
              <span className="hero-title">&</span>
              <span className="hero-title">Synchronize</span>
              <span className="hero-title">your</span>
              <span className="hero-title">Data</span>
              <span className="hero-span">
                <AnimatePresence mode="wait">
                  <motion.span
                    key={currentWordIndex}
                    initial={{ y: 54.5 }}
                    animate={{ y: 0 }}
                    exit={{ y: -54.5 }}
                    transition={{ duration: 0.6 }}
                    className="inner-span"
                  >
                    {words[currentWordIndex]}
                  </motion.span>
                </AnimatePresence>
              </span>
              <span className="hero-span2">
                <AnimatePresence mode="wait">
                  <motion.span
                    key={currentWordIndex}
                    initial={{ y: 40 }}
                    animate={{ y: 0 }}
                    exit={{ y: -40 }}
                    transition={{ duration: 0.6 }}
                    className="inner-span"
                  >
                    {words[currentWordIndex]}
                  </motion.span>
                </AnimatePresence>
              </span>
            </h1>
            <p className="hero-p">
              Get a fresh outlook on your business processes and strategic
              initiatives with the expertise of our skilled workflow architects
              and system integrators.
            </p>
            <button className="hero-button"  onClick={() => history.push(`/register`)}>
              <p>Get started now</p>
              <img src={arrRight} alt="get sarted" />
            </button>
          </div>
          <div className="hero-img-section">
            <img src={card1} alt="data" className="hero-card1" />
            <img src={heroImg} alt="backup your data" className="hero-img" />
            <img src={card2} alt="data" className="hero-card2" />
          </div>
        </div>
        <div className="startups">
          <h2>
            Join leading companies and startups in backing up and syncing their
            data with Sync.
          </h2>
          <div className="startup-logos">
            <img src={fox} alt="fox" />
            <img src={uoa} alt="uoa" />
            <img src={adobe} alt="adobe" />
            <img src={citrix} alt="citrix" />
            <img src={ea} alt="ea" />
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="main-section-inner">
          <Features />
        </div>
      </div>
      <div className="step-section">
        <div className="step-section-inner">
          <Innovation />
          <Workflow />
        </div>
      </div>
      <div className="carousel-section">
        <div className="carousel-section-inner">
          <Reviews />
          <FAQ />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
