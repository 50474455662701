import React from "react";
import lock from "../../../assets/icons/lockIcon.svg";
import unlock from "../../../assets/icons/errorIcon.svg";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
    marginLeft: "2%",
    marginTop: "-1%",
    color: "black",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "2% 1% 0 1%",
    // borderBottom: "1px solid #F7F7F7",
    width: "100%",
    alignItems: "center",
  },
  redBtn: {
    backgroundColor: "#E12D39",
    color: "white",
    padding: "1% 3%",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    width: "159px",
    height: "40px",
    fontSize: "14px",
  },
  yellowBtn: {
    backgroundColor: "#F7C948",
    color: "white",
    padding: "1% 3%",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    width: "159px",
    height: "40px",
    fontSize: "14px",
  },
  infoText: {
    display: "flex",
    margin: "7% 0",
    fontSize: "15px",
  },
  image: {
    width: "5%",
  },
}));

const WorkflowPop = ({
  isProtected,
  org,
  handleWorkPopClose,
  orgId,
  count,
  fileCount,
}) => {
  const classes = useStyles();

  return (
    <div onMouseLeave={handleWorkPopClose} className={classes.container}>
      <div className={classes.header}>
        {isProtected === true ? (
          <img className={classes.image} src={lock} alt="lock" />
        ) : (
          <img className={classes.image} src={unlock} alt="unlock" />
        )}
        <div style={{ width: "90%", textAlign: "center" }}>
          <h5 style={{ fontSize: "16px" }}>Backed Up Workflows</h5>
        </div>
      </div>
      <div className={classes.infoText}>
        {isProtected === true ? (
          <h5 style={{ fontSize: "14px" }}>
            Workflows connection is still active{" "}
          </h5>
        ) : (
          <h5 style={{ fontSize: "14px" }}>
            Workflows connection is currently not active kindly reconnect
          </h5>
        )}
      </div>
      <div>
        {isProtected === true ? null : (
          <button
            onClick={() => {
              window.open(
                "https://workflow-automation.podio.com/flows.php",
                "_blank"
              );
            }}
            className={classes.yellowBtn}
          >
            Connect Now
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkflowPop;
