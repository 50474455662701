import React, { useState } from "react";
import "./style.css";
import logo from "../../Images/logo.png";
import cancel from "../../Images/cancel.png";
import menu from "../../Images/menu.svg";
// import { Link, NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,

      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.7,
      },
    },
  };
  return (
    <div className="header-cont">
      <div className="header-inner">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key="overlay"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              className={`overlay ${isOpen ? "active" : ""}`}
              onClick={toggleMenu}
            ></motion.div>
          )}
        </AnimatePresence>
        <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
          <button
            onClick={toggleMenu}
            className={`close-menu ${isOpen ? "active" : ""}`}
          >
            <img src={cancel} alt="close menu" />
          </button>
          <div className="mob-nav">
            <NavLink exact onClick={toggleMenu} to="/">
              Home
            </NavLink>
            <NavLink onClick={toggleMenu} to="/integrations">
              Integration
            </NavLink>
            <NavLink onClick={toggleMenu} to="/pricing">
              Pricing
            </NavLink>
            <Link onClick={toggleMenu} to="/login">
              Login
            </Link>
            <button onClick={toggleMenu} className="mob-create-account">
              Create account
            </button>
          </div>
        </div>
        <Link to="/">
          <img src={logo} alt="logo" className="header-logo" />
        </Link>
        <nav id="nav-links" className="navlink-cont">
          <NavLink exact to="/">
            Home
          </NavLink>
          <NavLink to="/integrations">Integration</NavLink>
          <NavLink to="/pricing">Pricing</NavLink>
        </nav>
        <div className="login-cont">
          <Link to="/login" className="header-login">
            Login
          </Link>{" "}
          <Link to="/register">
            <button className="create-account" style={{ color: "black" }}>
              Create account
            </button>
          </Link>
        </div>
        <button onClick={toggleMenu} className="ham-menu">
          <img src={menu} alt="nav menu" />
        </button>
      </div>
    </div>
  );
}
