import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import $ from "jquery";
import "./Card.css";
import { defaultCard, deleteCard, saveCard } from "../../services/sync";
import { toast } from "../../helpers/apiRequests";
import { getMe } from "../../services/auth";
import ActionModal from "../../components/ConfirmModal/ActionModal";
import RenderByPermission from "components/RenderByPermission";
// import visaLogo from "../../assets/icons/visaLogo.svg";
// import { refreshBluesnapToken } from "utils/bilingRequests";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "relative",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

function changeImpactedElement(tagId, removeClass, addClass) {
  removeClass = removeClass || "";
  addClass = addClass || "";
  $("[data-bluesnap=" + tagId + "]")
    .removeClass(removeClass)
    .addClass(addClass);
}

const bluesnapSetUp = (bluesnapToken) => {
  const script = document.createElement("script");
  script.src = "https://bluesnap.com/web-sdk/4/bluesnap.js";

  // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
  const cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const bsObj = {
    //insert your Hosted Payment Fields token
    token: bluesnapToken,
    onFieldEventHandler: {
      onFocus: function (tagId) {
        // Handle focus
        changeImpactedElement(tagId, "", "hosted-field-focus");
      },
      onBlur: function (tagId) {
        // Handle blur
        changeImpactedElement(tagId, "hosted-field-focus");
      },
      onError: function (tagId, errorCode, errorDescription) {
        // Handle a change in validation by displaying help text
        $("#" + tagId + "-help")
          .removeClass("helper-text-green")
          .text(errorDescription);
      },
      onType: function (tagId, cardType, cardData) {
        // get card type from cardType and display card image
        $("#card-logo > img").attr("src", cardUrl[cardType]);
        if (null != cardData) {
          $("#" + tagId + "-help")
            .addClass("helper-text-green")
            .text("Okay.");
        }
      },
      onValid: function (tagId) {
        // Handle a change in validation by removing any help text
        $("#" + tagId + "-help").text("");
      },
    },
    style: {
      // Styling all inputs
      input: {
        "font-size": "14px",
        "font-family":
          "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
        "line-height": "1.42857143",
        color: "#555",
      },
      // Styling input state
      ":focus": {
        color: "#555",
      },
    },
    ccnPlaceHolder: "1234 5678 9012 3456",
    cvvPlaceHolder: "123",
    expPlaceHolder: "MM / YY",
  };
  script.onload = () => {
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
  };
  document.body.appendChild(script);
};

const BillingCards = (props) => {
  const classes = useStyles();
  const tableStyle = {
    display: "block",
    width: "100%",
  };
  const columns = props.columns;

  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const section = urlParams.get("section")

  const [loading] = useState(false);
  const [cardModal, setShouldShowCardModal] = useState(props.billings);
  const [bluesnapToken, setBlueSnapToken] = useState(props.bluesnapToken);
  const [actionModal, setActionModal] = useState({
    value: false,
    message: "",
    id: null,
    type: null,
  });

  function handleClose() {
    props.functionThreeMain();
    setShouldShowCardModal(false);
  }

  /* Calling bluesnap.submitCredentials: function that submits card data to
  BlueSnap and calls input function with card data object if submission was successful */
  function submitCard(e) {
    e.preventDefault();
    window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
      if (null != callback.error) {
        const errorArray = callback.error;
        for (const i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            errorArray[i].errorCode + " - " + errorArray[i].errorDescription
          );
        }
      } else {
        const cardData = callback.cardData;
        const name = $("#cardholder-name").val();
        saveCard({
          name,
          email: props.user.email,
          bluesnap_token: bluesnapToken,
          last_four_digit: cardData.last4Digits,
          card_brand: cardData.ccType,
          card_expire: cardData.exp,
        })
          .then((res) => {
            const response = JSON.parse(res.data);
            if (typeof response["message"] !== "undefined") {
              if (Array.isArray(response["message"])) {
                toast.notify(response.message[0].description);
                return false;
              }
            }
            toast.notify("Card saved successfully!");
            props.loadCards();
            setShouldShowCardModal(false);
          })
          .catch((error) => {
            toast("Failed to add card!");
            // refreshBluesnapToken().then(data => {
            //   if (data?.status && data?.token) {
            //     setBlueSnapToken(data?.token)
            //   }
            // })
          })
          .catch((error) => {
            toast.notify("Failed to add card!", { type: "error" });
          });
      }
    });
  }

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  React.useEffect(() => {
    bluesnapSetUp(bluesnapToken);
  }, [bluesnapToken, cardModal]);

  const toggleActionModal = (value, message, id, type) => {
    setActionModal({ value, message, id, type });
  };

  const makeCardDefault = (id) => {
    defaultCard({ card_id: id })
      .then((res) => {
        props.loadCards();
        setActionModal({ value: false, message: "", id: null, type: null });
      })
      .catch((error) => {
        toast.notify(
          "An error occurred while try make card default, try again later or contact our support team",
          { type: "error" }
        );
        setActionModal({ value: false, message: "", id: null, type: null });
      });
  };

  const deleteMainCard = (id) => {
    deleteCard({ card_id: id })
      .then((res) => {
        props.loadCards();
        setActionModal({ value: false, message: "", id: null, type: null });
      })
      .catch((error) => {
        toast.notify(
          "An error occurred while try to delete default, try again later or contact our support team",
          { type: "error" }
        );
        setActionModal({ value: false, message: "", id: null, type: null });
      });
  };

  const performAction = () => {
    if (actionModal.type === "default") {
      makeCardDefault(actionModal.id);
    }

    if (actionModal.type === "delete") {
      deleteMainCard(actionModal.id);
    }
  };



  return (
    <>
      {actionModal.value ? (
        <ActionModal
          message={actionModal.message}
          toggleActionModal={toggleActionModal}
          performAction={performAction}
        />
      ) : (
        ""
      )}
      <Card className="w-full mb-16" style={tableStyle}>
        <Button
          variant="contained"
          color="primary"
          // onClick={() => saveCard()}
          onClick={() => setShouldShowCardModal(true)}
        >
          Add Card
        </Button>
        <br />
        <br />

        {/* <div>
          {props.cardList.data.map((card) => (
            <div key={card._id}>
              <div className="d-flex align-items-center justify-content-center">
                <h5>Account Name:</h5>
                <h5>Willams Nwagbugu</h5>
                <img
                  src={card.card_brand === "VISA" ? visaLogo : null}
                  alt="Visa Logo"
                />
              </div>
              <h3>{card.card_brand}</h3>
             
            </div>
          ))}
        </div> */}
        
        <MaterialTable
          title="Card Details"
          columns={columns}
          data={props.cardList.data}
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage:
                "No cards in your vault, kindly click on the link above to add one.",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          actions={[
            {
              icon: "settings_system_daydream",
              tooltip: "Make card default",
              onClick: (event) => {
                event.disabled = true;
                // setConfirmModal({
                //   message: "",
                //   modal_type: "card_default",
                //   modal_value: true,
                //   modal_data: rowData,
                // });
              },
            },
            {
              icon: "delete",
              tooltip: "Remove card",
              onClick: (event) => {
                event.disabled = true;
                // setConfirmModal({
                //   message: "",
                //   modal_type: "delete_card",
                //   modal_value: true,
                //   modal_data: rowData,
                // });
              },
            },
          ]}
          components={{
            Action: (props) => {
              if (props.action.icon === "settings_system_daydream") {
                return (
                  <React.Fragment>
                    <Button
                      onClick={(event) => {
                        toggleActionModal(
                          true,
                          "Are you sure about making this card default?",
                          props.data.hidden_id,
                          "default"
                        );
                      }}
                      color="primary"
                      variant="text"
                      style={{
                        fontSize: "12px !important",
                        marginRight: "10px !important",
                        display: props.data.hidden_action === 1 ? "none" : "",
                      }}
                    >
                      Make Default
                    </Button>
                  </React.Fragment>
                );
              }

              if (props.action.icon === "delete") {
                return (
                  <React.Fragment>
                    {props.data.hidden_action === 1 ? (
                      <Button disabled style={{ marginRight: "10px" }}>
                        Default
                      </Button>
                    ) : (
                      ""
                    )}
                    <RenderByPermission allowTeamMemberAccess={false}>
                      <Tooltip
                        style={{ marginLeft: "-10px" }}
                        title="Delete Card"
                      >
                        <IconButton
                          onClick={(e) => {
                            toggleActionModal(
                              true,
                              "Are you sure about deleting this card?",
                              props.data.hidden_id,
                              "delete"
                            );
                          }}
                        >
                          <DeleteIcon>Delete Card</DeleteIcon>
                        </IconButton>
                      </Tooltip>
                    </RenderByPermission>
                  </React.Fragment>
                );
              }
            },
          }}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: {
              width: "200px",
              margin: "auto",
              textAlign: "center",
              // display: 'grid',
              // gridTemplateColumns: '200px',
            },
          }}
        />
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cardModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={cardModal} style={{ width: "50%" }}>
          <div className={classes.paper} style={{ width: "50%" }}>
            <h2 id="transition-modal-title">Add a new card</h2>
            <p id="transition-modal-description">Securely add a new card</p>
            <div className="card-name">
              <label htmlFor="name">Card Holder</label>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="cardholder-name"
                placeholder="Fullname"
                name="name"
                autoComplete="name"
              />
            </div>

            <div className="card-number">
              <div className="div-inner">
                <label htmlFor="cvv">Card Number</label>
                <div
                  className="hosted-textfield div-inner"
                  id="card-number"
                  data-bluesnap="ccn"
                />
                <span className="helper-text" id="ccn-help" />
              </div>
              <div id="card-logo" className="card-logo">
                <img
                  src="https://files.readme.io/d1a25b4-generic-card.png"
                  height="30px"
                  alt="Sync"
                />
              </div>
            </div>

            <div className="card-extras">
              <div className="div-inner">
                <label htmlFor="cvv">CVV</label>
                <div
                  className="hosted-textfield"
                  id="card-number"
                  data-bluesnap="cvv"
                />
                <span className="helper-text" id="cvv-help" />
              </div>

              <div>
                <label htmlFor="exp-date">Exp. (MM/YY)</label>
                <div
                  className="hosted-textfield"
                  id="exp-date"
                  data-bluesnap="exp"
                />
                <span className="helper-text" id="exp-help" />
              </div>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => submitCard(e)}
              className={classes.submit}
            >
              Save Card
            </Button>

            {/*<br />*/}
            {/*<br />*/}
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    style={{ marginRight: "4rem" }}*/}
            {/*>*/}
            {/*  Save Card*/}
            {/*</Button>*/}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(BillingCards);
