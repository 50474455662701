import React from "react";
import "./footer.css";
import arrRight from "../../Images/arrow-right-white.png";
import logo from "../../Images/logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Products from "containers/Website/widgets/Products";

export default function Footer() {
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-cont">
      <div className="footer-get-started">
        <h2>Ready to get started?</h2>
        <p>
          Let us bring you up to speed on automation, syncing, and gaining
          insight on your data
        </p>
        <button onClick={() => history.push(`/register`)}>
          <p>Get started now</p>
          <img src={arrRight} alt="get started" />
        </button>
      </div>
      <div className="footer-links">
        <div className="footer-logo-cont">
          <Link to="/">
            <img src={logo} alt="thatapp logo" />
          </Link>
          <p>Copyright © {currentYear} TechEgo. All rights reserved.</p>
        </div>
        <div className="other-footer-links">
          <h2>Products</h2>
          <Products text="Features" />
          <p
            onClick={() => {
              history.push("/integrations");
              scrollTop();
            }}
          >
            Integration
          </p>
          <div
            className="item link22"
            onClick={() => {
              window.open("https://thatapp1.statuspage.io/", "_blank");
            }}
            style={{ color: "black", cursor: "pointer" }}
          >
            <a
              className="link"
              href="http://help.thatapp.io/"
              target="_blank"
              style={{ color: "black", cursor: "pointer" }}
              rel="noopener noreferrer"
            >
              Status Page
            </a>
          </div>
          <p
            onClick={() => {
              history.push("/pricing");
              scrollTop();
            }}
          >
            Pricing
          </p>
        </div>

        <div style={{ width: "250px" }} className="other-footer-links">
          <h2>AVA iPaaS Documentation</h2>
          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/getting-started/", "_blank");
            }}
          >
            Get Started
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/guides/", "_blank");
            }}
          >
            Integrator Guide
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/components/", "_blank");
            }}
          >
            Components
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/developers/", "_blank");
            }}
          >
            Components Developers
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/releases/", "_blank");
            }}
          >
            Releases
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/references/", "_blank");
            }}
          >
            References
          </p>

          <p
            onClick={() => {
              window.open("https://docs.thatapp.io/admin/", "_blank");
            }}
          >
            Support
          </p>
        </div>

        <div className="other-footer-links">
          <h2>Help Article</h2>

          <p
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/collections/209696-sync",
                "_blank"
              );
            }}
          >
            Sync
          </p>
          <p
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/collections/209662-print",
                "_blank"
              );
            }}
          >
            Print
          </p>
          <p
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/collections/5300754-releases",
                "_blank"
              );
            }}
          >
            Product release note
          </p>

          <Link to="/new_contact">
            <p>Contact us</p>
          </Link>
        </div>
        <div className="other-footer-links">
          <h2>Other</h2>

          <a
            href="https://help.thatapp.io/en/articles/108584-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Privacy policy</p>
          </a>

          <a
            href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Terms and conditions</p>
          </a>
        </div>
      </div>
    </div>
  );
}
