import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import "./styles.css";
import RenderByPermission from "components/RenderByPermission";
import { formatNumberWithKAndM } from "utils/formatNumberWithKAndM";
import { Tooltip } from "@material-ui/core";
// import { formatNumber } from "utils/formatNumber";
import dashIcon from "../../assets/icons/dashIcon.svg";
import upndown from "../../assets/icons/upndown.svg";
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

// synced,
const Summary = ({
  spaceCount,
  appCount,
  flowCount,
  workflowCount,
  itemsCount,
  click,
  orgs,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <h5
          className="dashboard_title"
          style={{ margin: "0", fontWeight: "bold" }}
        >
          {" "}
          Dashboard{" "}
        </h5>
        <RenderByPermission allowTeamMemberAccess={false}>
          <button
            id="syncAnOrgButton"
            className={orgs?.length < 1 ? "sync__btn jiggle" : "sync__btn"}
            onClick={click}
            style={{ marginBottom: "0" }}
          >
            Sync an Organisation
          </button>
        </RenderByPermission>
      </div>

      <div className="summary-tabs mt-5">
        <div
          className="item item-first"
          style={{ borderRadius: "12px 0 0 12px" }}
        >
          <div className="image">
            <img src={dashIcon} alt="users" />
          </div>

          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              All Organisations
            </div>
            <Tooltip
              title={`${formatNumberWithKAndM(
                orgs?.length ?? 0
              )} Organisations`}
            >
              <div
                style={{ fontSize: "32px", fontWeight: "800" }}
                className="value"
              >
                {formatNumberWithKAndM(orgs?.length ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Synced Organisations
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="spaces" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Spaces across all Organisations
            </div>
            <Tooltip
              title={`${formatNumberWithKAndM(
                spaceCount ?? 0
              )} Spaces across all Organisations`}
            >
              <div className="value">
                {formatNumberWithKAndM(spaceCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                All Spaces
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Apps across all Spaces
            </div>
            <Tooltip title={`${appCount ?? 0} Apps across all Spaces`}>
              <div className="value">
                {formatNumberWithKAndM(appCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Apps
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="items" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Items across all Apps
            </div>
            <Tooltip title={`${itemsCount ?? 0} Items across all Apps`}>
              <div className="value">
                {formatNumberWithKAndM(itemsCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Items
              </p>
            </div>
          </div>
        </div>

        <div
          style={{ borderRadius: "0 12px 12px 0" }}
          className="item item-last"
        >
          <div className="image">
            <img src={dashIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Workflows across all Organisations
            </div>
            <Tooltip
              title={`${workflowCount ?? 0} Workflows across all Organisations`}
            >
              <div className="value">
                {formatNumberWithKAndM(workflowCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Workflows
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
