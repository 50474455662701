import React from "react";
import "./features.css";
import integrate from "../../Images/integrate.png";
import backup from "../../Images/backup.png";
import user from "../../Images/user.png";
import printing from "../../Images/printing.png";

export default function Features() {
  return (
    <div className="feature-cont">
      <div className="text-cont">
        <h2>
          Gain an unfair advantage with cognitive <span>features</span>
        </h2>
        <p>
          It's our job to ensure that you're never lost in the clouds. Gain a
          refreshing perspective of your business processes and strategic
          initiatives.
        </p>
      </div>
      <div className="features-card">
        <div className="feature">
          <img src={integrate} alt="integrate" />
          <h2>Integrate. Automate. Relax.</h2>
          <p>
            AVA is our Artificial Intelligence and your Automated Virtual
            Assistant. As a Hybrid Integration Platform for your ERP, SaaS, IoT
          </p>
        </div>
        <div className="feature">
          <img src={backup} alt="backup" />
          <h2>Backup and Protect your Sensitive Data</h2>
          <p>
            Sync enables you to backup your entire organization's data, ensuring
            protection moving forward
          </p>
        </div>
        <div className="feature">
          <img src={printing} alt="integrate" />
          <h2>Printing Templates, Blockchain security and Smart Contracts</h2>
          <p>
            Your time is valuable. Don’t waste it by creating manual documents
          </p>
        </div>
        <div className="feature">
          <img src={user} alt="integrate" />
          <h2>User Management made Simple</h2>
          <p>
            Managing access rights and the roles/responsibilities for all of
            your employees in any system can be difficult. We've launched Audit
            to help with just that!
          </p>
        </div>
      </div>
    </div>
  );
}
