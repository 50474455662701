import React from 'react'
import { useHistory } from 'react-router-dom'
// components
// import Navigation from '../components/Navigation'
// import Accordion from '../components/Accordion'
import GetStarted from '../components/GetStarted'
import Footer from '../components/Footer'
// widgets
import Button from '../widgets/buttons/Button'
// style
import './styles/pricing.scss'
// icons
import check2 from '../icons/check-mark2.svg'
import check3 from '../icons/check-mark3.svg'
import dash from '../icons/dash.svg'
// import Header from 'containers/NewWebsite/components/shared/Header'
import Navigation from '../components/Navigation'
// data
// const faq = [
//     {
//         title: "What exactly is thatapp.io about?",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     },
//     {
//         title: "What can open a thatapp.io account?",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     },
//     {
//         title: "How do i open a thatapp.io account",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     },
//     {
//         title: "Does my subscription cover all the features",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     },
//     {
//         title: "Does thatapp.io have a free plan",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     },
//     {
//         title: "Can i cancel my subscription or switch to another plan anytime",
//         text: "You can open a thatapp.io account from one of the links above and choose a suitable plan for you."
//     }
// ]

const Pricing = () => {
    const history = useHistory()
    return <div className="pricing">
        <Navigation btnBg="bg-bd1-1" color="color-1" />
        {/* <Header /> */}
        <section className="landing">
            <div className="head">
                <div className="font-40 color-1">Choose the plan that’s right for you</div>
                <div className="color-black-2">Pay by month or the year, and cancel at any time.</div>
            </div>
            <div style={{display: "flex"}} className="quintuple-container">

                <div className="item ml-2 mr-2">
                    <div className="font-14 bg-4 popular">MOST POPULAR</div>
                    <div className="content">
                        <div className="font-24 bolder name">Starter</div>
                        <div className="font-14">For entrepreneurs and teams to start selling smarter.</div>
                        <div className="color-1 price">
                            <span className="font-50">$35</span>
                            <span className="font-24">/Month</span>
                        </div>
                        <Button
                            text="Get Started"
                            function={() => history.push('/register')}
                            styling="bg-bd5-5-fx full-input"
                        />
                        <div className="font-18 features">
                            <div><img src={check2} alt="check" /> <span>1 Organisation</span></div>
                            {/* <div><img src={check2} alt="check" /> <span>1 Connection</span></div> */}
                        </div>
                    </div>
                </div>

                <div className="item mr-2 ml-2">
                    <div className="font-14 bg-4 popular">MOST POPULAR</div>
                    <div className="content">
                        <div className="font-24 bolder name">Business</div>
                        <div className="font-14">For entrepreneurs and teams to start selling smarter.</div>
                        <div className="color-1 price">
                            <span className="font-50">$60</span>
                            <span className="font-24">/Month</span>
                        </div>
                        <Button
                            text="Get Started"
                            function={() => history.push('/register')}
                            styling="bg-bd5-5-fx full-input"
                        />
                        <div className="font-18 features">
                            <div><img src={check2} alt="check" /> <span>2 Organisations</span></div>
                            {/* <div><img src={check2} alt="check" /> <span>2 Connection</span></div> */}
                        </div>
                    </div>
                </div>

                <div className="item popular-item ml-2 mr-2">
                    <div className="font-14 bg-4 popular">MOST POPULAR</div>
                    <div className="content">
                        <div className="font-24 bolder name">Enterprise</div>
                        <div className="font-14">For entrepreneurs and teams to start selling smarter.</div>
                        <div className="color-1 price">
                            <span className="font-50">$100</span>
                            <span className="font-24">/Month</span>
                        </div>
                        <Button
                            text="Get Started"
                            function={() => history.push('/register')}
                            styling="bg-bd5-5-fx full-input"
                        />
                        <div className="font-18 features">
                            <div><img src={check2} alt="check" /> <span>4 Organisations</span></div>
                            {/* <div><img src={check2} alt="check" /> <span>3 Connection</span></div> */}
                        </div>
                    </div>
                </div>

                <div className="item ml-2 mr-2">
                    <div className="font-14 bg-4 popular">MOST POPULAR</div>
                    <div className="content">
                        <div className="font-24 bolder name">Data Scientist</div>
                        <div className="font-14">For entrepreneurs and teams to start selling smarter.</div>
                        <div className="color-1 price">
                            <span>$250</span>
                            <span>/Month</span>
                        </div>
                        <Button
                            text="Get Started"
                            function={() => history.push('/register')}
                            styling="bg-bd5-5-fx full-input"
                        />
                        <div className="font-18 features">
                            <div><img src={check2} alt="check" /> <span>10 Organisations</span></div>
                            {/* <div><img src={check2} alt="check" /> <span>5 Connection</span></div> */}
                        </div>
                    </div>
                </div>

                <div className="item mr-2 ml-2">
                    <div className="font-14 bg-4 popular">MOST POPULAR</div>
                    <div className="content">
                        <div className="font-24 bolder name">White Glove</div>
                        <div className="font-14">For entrepreneurs and teams to start selling smarter.</div>
                        <div className="color-1 price">
                            <span className="font-50">$400</span>
                            <span className="font-24">/Month</span>
                        </div>
                        <Button
                            text="Get Started"
                            function={() => history.push('/register')}
                            styling="bg-bd5-5-fx full-input"
                        />
                        <div className="font-18 features">
                            <div><img src={check2} alt="check" /> <span>15 Organisations</span></div>
                            {/* <div><img src={check2} alt="check" /> <span>Unlimited</span></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="plan-details">
            <div className="head font-40 color-1">All Features</div>
            <div className="plan-details-container">
                <div style={{display: "grid", gridTemplateColumns: "1fr 5fr"}}  className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-1 font-24">SYNC</div>
                                <div className="color-black font-20">Features</div>
                            </div>
                            <div className="tab">Organisations</div>
                            <div className="tab">Space</div>
                            <div className="tab">App</div>
                            <div className="tab">Item</div>
                            <div className="tab">Revisions</div>
                            <div className="tab">Comments</div>
                            <div className="tab">Hooks</div>
                            <div className="tab">Contacts</div>
                            <div className="tab">Tasks</div>
                            <div className="tab">Files</div>
                        </div>
                    </div>

                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-black font-20">Data Interaction</div>
                            </div>
                            <div className="tab">Item Creation</div>
                            <div className="tab">Custom Filters</div>
                            <div className="tab">Row Grouping</div>
                            <div className="tab">Pivot Reporting</div>
                            <div className="tab">Real time Data</div>
                            <div className="tab">Data Export</div>
                            <div className="tab">Saved Views</div>
                            <div className="tab">Cell Editing</div>
                            <div className="tab">Nested Files</div>
                            <div className="tab">Sync Charts</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-black font-20">Data Restoration</div>
                            </div>
                            <div className="tab">Items</div>
                            <div className="tab">Comments</div>
                            <div className="tab">Tasks</div>
                            <div className="tab">Files</div>
                            <div className="tab">App Fields</div>
                            <div className="tab">Applications</div>
                            <div className="tab">Workspaces</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-black font-20">Tools</div>
                            </div>
                            <div className="tab">GDPR Settings</div>
                            <div className="tab">Hook Killer</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-1 font-24">AUDIT</div>
                            </div>
                            <div className="tab">Queue Management</div>
                            <div className="tab">User Management</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-black font-20">Event Logging</div>
                            </div>
                            <div className="tab">Creation</div>
                            <div className="tab">Updates</div>
                            <div className="tab">Deletes</div>
                            <div className="tab">Shared Items</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-1 font-24">FILE HAVEN</div>
                            </div>
                            <div className="tab">Logic Builder</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-1 font-24">IRIS GENESIS</div>
                            </div>
                            <div className="tab">Queue Management</div>
                            <div className="tab">User Management</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-1 font-24">PRINT</div>
                            </div>
                            <div className="tab">Templates</div>
                            <div className="tab">Production</div>
                            <div className="tab">Development</div>
                            <div className="tab">JSON</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab">2</div>
                                <div className="tab">1</div>
                                <div className="tab">1</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab">5</div>
                                <div className="tab">3</div>
                                <div className="tab">2</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab">10</div>
                                <div className="tab">5</div>
                                <div className="tab">5</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab">20</div>
                                <div className="tab">10</div>
                                <div className="tab">10</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab">Unlimited</div>
                                <div className="tab">Unlimited</div>
                                <div className="tab">Unlimited</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

                <div className="double-container-1-5">
                    <div className="features item">
                        <div className="inner">
                            <div className="bold tab-header">
                                <div className="color-black font-20">AVA</div>
                            </div>
                            <div className="tab">Workspaces</div>
                            <div className="tab">Production</div>
                            <div className="tab">Development</div>
                            <div className="tab">Git Repo</div>
                        </div>
                    </div>
                    <div className="quintuple-container2">
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Starter</div>
                                    <div>$35 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Business</div>
                                    <div>$60 / Month</div>
                                </div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Entreprise</div>
                                    <div>$100 / Month</div>
                                </div>
                                <div className="tab">1</div>
                                <div className="tab">1</div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">Data Reparo</div>
                                    <div>$250 / Month</div>
                                </div>
                                <div className="tab">1</div>
                                <div className="tab">1</div>
                                <div className="tab">1</div>
                                <div className="tab"><img src={dash} alt="dash" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                        <div className="item">
                            <div className="inner">
                                <div className="tab-header">
                                    <div className="color-black font-20 bold">White Glove</div>
                                    <div>$400 / Month</div>
                                </div>
                                <div className="tab">Unlimited</div>
                                <div className="tab">Unlimited</div>
                                <div className="tab">Unlimited</div>
                                <div className="tab"><img src={check3} alt="check" /></div>
                            </div>
                            <Button
                                text="Get Started"
                                function={() => history.push('/register')}
                                styling="bg-bd5-5-fx btn2"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </section>
        {/* <section className="faq bg-2">
            <div className="head">
                <div className="font-32 bolder">Frequently Asked Questions</div>
                <div className="font-24 color-black-2">Got questions? We got answeres to them.</div>
            </div>
            {
                faq.map(item => {
                    return <Accordion title={item.title} text={item.text} />
                })
            }
        </section> */}
        <GetStarted btnBg="bg-bd1-1" bg="bg-3" color="color-1" function={() => history.push('/register')} />
        <Footer bg="bg-3" color="color-1" />
    </div>
}

export default Pricing
